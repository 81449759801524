import { Typography } from 'maple-storybook'
import { fileAttachmentThumbnails } from 'maple-storybook/utils'
import moment from 'moment'

import { darkGrayColor, whiteChipColor } from 'variables/styles'

import { checkArrayPresence, formatLongText } from './applicationHelper'
import { PMS_DATE_FORMAT, TWO_DIGIT_FORMAT } from './constants'
import { formatDate, getYearFromDate } from './datesHelper'

export const ConfigTabs = [
  {
    value: '/performance-management/team',
    label: 'Team',
    href: '/performance-management/team'
  },
  {
    value: '/performance-management/review-settings',
    label: 'Review Settings',
    href: '/performance-management/review-settings'
  }
]

export const reviewSearchFilters = [
  {
    label: 'Department',
    value: 'departments',
    component: 'selectWrapper',
    placeholder: 'Select Department(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Designations',
    value: 'designations',
    component: 'selectWrapper',
    placeholder: 'Select Designation(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Employment Types',
    value: 'employment_types',
    component: 'selectWrapper',
    placeholder: 'Select Employment Type(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Location',
    value: 'locations',
    component: 'selectWrapper',
    placeholder: 'Select Location(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Review Period',
    value: 'review_period',
    component: 'selectWrapper',
    placeholder: 'Select Review Period(s)'
  },
  {
    label: 'Status',
    value: 'review_setting_status',
    component: 'selectWrapper',
    placeholder: 'Select Status(s)'
  }
]

export const isConfigTabs = (path) =>
  ['/performance-management/team', '/performance-management/review-settings'].includes(path) || false

export const myTeamTabs = [
  {
    value: '/performance/my-team',
    label: 'Teammates',
    href: '/performance/my-team'
  },
  {
    value: '/performance/kpi-and-goals',
    label: "Goals & KPI's",
    href: '/performance/kpi-and-goals'
  }
]

export const isMyTeamTabs = (path) => ['/performance/my-team', '/performance/kpi-and-goals'].includes(path) || false

export const applicableOnOptions = [
  { label: 'Department', value: 'departments' },
  { label: 'Designation', value: 'designations' },
  { label: 'Employment Type', value: 'employment_types' },
  { label: 'Location', value: 'locations' }
]

export const getApplicableOnData = (EmploymentType, Department, Title, Location) => {
  const array = []
  if (checkArrayPresence(EmploymentType)) array.push({ name: 'Employment Type' })
  if (checkArrayPresence(Department)) array.push({ name: 'Department' })
  if (checkArrayPresence(Title)) array.push({ name: 'Designation' })
  if (checkArrayPresence(Location)) array.push({ name: 'Location' })
  return array
}

export const getBindableTitles = (data) => {
  const result = {
    Department: [],
    Title: [],
    Location: []
  }
  data.forEach((item) => {
    if (result[item.bindable_type]) {
      result[item.bindable_type].push({ id: item.id, value: item.bindable_id, name: item.bindable_title })
    }
  })

  return result
}

export const getConfigInitialPayload = (payload) => {
  const initialValues = { ...payload }
  delete initialValues.performance_review_settings_bindings_data
  delete initialValues.impact_area_settings_attributes_data
  delete initialValues.reminder_settings_attributes_data

  const result = getBindableTitles(payload.performance_review_settings_bindings_data)

  initialValues.departments = result.Department
  initialValues.designations = result.Title
  initialValues.locations = result.Location
  initialValues.impact_area_settings_attributes = payload.impact_area_settings_attributes_data
  initialValues.reminder_settings_attributes = payload.reminder_settings_attributes_data
  initialValues.review_setting_status = !payload.deleted_at

  return initialValues
}

export const triggeredOnOptions = [
  {
    label: 'Sync Start Date',
    value: 'sync_start_date'
  },
  {
    label: 'Sync End Date',
    value: 'sync_end_date'
  },
  {
    label: 'Review Start Date',
    value: 'review_start_date'
  },
  {
    label: 'Review End Date',
    value: 'review_end_date'
  }
]

export const startEndOptions = (start, end, percentage = false) => {
  const numberArray = []
  for (let i = start; i <= end; i++) {
    numberArray.push({ label: `${i.toString()} ${percentage ? '%' : ''}`, value: i.toString() })
  }
  return numberArray
}

export const userKpiInputOptions = startEndOptions(0, 100, true)

export const reviewFrequencyOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Bi-Annually', value: 'biannual' },
  { label: 'Annually', value: 'annual' }
]

export const mapNumberWithSequence = (number) => {
  switch (number % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const reviewStatusOptions = [
  {
    id: 0,
    label: 'Inactive',
    value: 'inactive'
  },
  {
    id: 1,
    label: 'Active',
    value: 'active'
  }
]

export const getTotalWeightPercentage = (values) => {
  let impactWeightPercentage = 100
  values?.impact_areas_attributes?.forEach((item) => {
    if (item?.weight) {
      impactWeightPercentage -= Number(item.weight)
    }
  })
  return impactWeightPercentage < 0 ? 0 : impactWeightPercentage
}

export const reviewConfigFilters = [
  { value: 'review_period', label: 'Review Period' },
  { value: 'review_setting_status', label: 'Status' }
]

export const userReviewStatuses = [
  { label: 'Upcoming', value: 'upcoming', color: 'purple-600' },
  { label: 'In Progress', value: 'in_progress', color: 'blue-700' },
  { label: 'Over Due', value: 'overdue', color: 'warning-500' },
  { label: 'Completed', value: 'completed', color: 'primary-600' }
]

export const myTeammatesFilters = (allFilters = false, configView, customSetter) => {
  let filters = [
    {
      label: 'Review Type',
      value: 'review_type',
      component: 'selectWrapper',
      multiOption: true,
      allCheck: true,
      placeholder: 'Select Review Type'
    },
    {
      label: 'Department',
      value: 'department_ids',
      component: 'selectWrapper',
      placeholder: 'Select Department',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Designation',
      value: 'designation_ids',
      component: 'selectWrapper',
      placeholder: 'Select Designation',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Status',
      value: 'status',
      component: 'selectWrapper',
      placeholder: 'Select Status'
    }
  ]
  if (allFilters) {
    filters.splice(0, 0, {
      label: 'Date Range',
      value: 'custom_date_range',
      fromDateValue: 'start_date',
      toDateValue: 'end_date',
      component: 'dateRangePickerWrapper'
    })

    filters.splice(
      5,
      0,
      {
        label: 'Reviewer Type',
        value: 'reviewer_type',
        component: 'selectWrapper',
        nestedSelection: true,
        selectedValue: customSetter.selectedReviewerType,
        setValue: customSetter.setSelectedReviewerType,
        placeholder: 'Select Review Type',
        nestedOptions: [
          {
            label: 'Tier',
            value: 'tier',
            component: 'selectWrapper',
            placeholder: 'Select Tier',
            conditionToRender: customSetter.selectedReviewerType
          }
        ]
      },
      {
        label: 'Lead',
        value: 'lead_id',
        component: 'selectWrapper',
        userSearchable: true,
        placeholder: 'Select Lead'
      },
      {
        label: 'Manager',
        value: 'manager_id',
        component: 'selectWrapper',
        userSearchable: true,
        placeholder: 'Select Manager'
      },
      {
        label: 'Reporting',
        value: 'reporting_to',
        userSearchable: true,
        component: 'selectWrapper',
        placeholder: 'Select Reporting'
      }
    )
  }
  if (configView) {
    filters.splice(2, 0, {
      label: 'Reviewer',
      placeholder: 'Select Reviewer',
      component: 'selectWrapper',
      value: 'reviewer_ids',
      userSearchable: true,
      multiOption: true
    })
  }
  return filters
}

export const myTeamReviewTableHeaders = (config) => {
  let array = ['Employee', 'Designation', 'Department', 'Review Type', 'Status', 'Syncs', 'Team']
  if (config) array.splice(4, 0, 'Reviewer')
  return array
}

export const goalAndKpiFilters = (allFilters) => {
  let newFilters = [
    {
      label: 'Review Settings',
      value: 'review_setting',
      notClear: true,
      component: 'selectWrapper',
      placeholder: 'Select Review Settings'
    },
    {
      label: 'Assignee',
      value: 'assignee',
      userSearchable: true,
      component: 'selectWrapper',
      placeholder: 'Select Status'
    }
  ]
  if (allFilters) {
    newFilters.splice(0, 0, {
      label: 'Review Type',
      value: 'review_period',
      nestedSelection: true,
      notClear: true,
      component: 'selectWrapper',
      resetFilter: ['quarter'],
      placeholder: 'Select Review Type',
      selectedValue: allFilters.reviewPeriod,
      setValue: allFilters.setReviewPeriod,
      nestedOptions: [
        {
          label: 'Quarter',
          value: 'quarter',
          notClear: true,
          component: 'selectWrapper',
          placeholder: 'Select Quarter',
          conditionToRender: true
        }
      ]
    })
  } else {
    newFilters.splice(0, 0, {
      label: 'Quarter',
      value: 'quarter',
      notClear: true,
      component: 'selectWrapper',
      placeholder: 'Select Quarter'
    })
    newFilters.splice(2, 0, {
      label: 'Review Type',
      value: 'review_period',
      notClear: true,
      component: 'selectWrapper',
      placeholder: 'Select Review Type'
    })
  }
  return newFilters
}

export const getAreaWeightage = (area, currentGoal) => {
  let totalWeightage = 0
  area.impact_area_goal_settings.map((area) => area.id !== currentGoal && (totalWeightage += area.weightage))
  return totalWeightage
}

export const getGoalReviewWeightage = (area) => {
  let totalWeightage = 0
  area.goals.map((area) => (totalWeightage += area.weight))
  return totalWeightage
}

export const getInitialGoalValues = (type, values) =>
  type === 'goal'
    ? {
        id: values.id,
        weight: values.weightage,
        goal_id: values.goal.id,
        kpis: checkArrayPresence(values.goal.goal_setting_kpis)
          ? values.goal.goal_setting_kpis.map((kpi) => ({
              id: kpi.id,
              kpi_id: kpi.kpi.id,
              assign_to: kpi.assign_to_team ? 'team' : 'individual',
              users: !kpi.assign_to_team ? kpi.user_kpis.map((user) => user.user.id) : undefined
            }))
          : []
      }
    : {
        kpis: checkArrayPresence(values.goal.goal_setting_kpis)
          ? values.goal.goal_setting_kpis.map((kpi) => ({
              id: kpi.id,
              kpi_id: kpi.kpi.id,
              assign_to: kpi.assign_to_team ? 'team' : 'individual',
              users: !kpi.assign_to_team ? kpi.user_kpis.map((user) => user.user.id) : undefined
            }))
          : []
      }

export const goalAssignedToOptions = [
  { label: 'Individual', value: 'individual' },
  { label: 'Team', value: 'team' }
]

export const ratingScaleValues = [
  {
    label: 'Exceeds Expectations',
    stars: 3,
    content:
      "Performance consistently exceeded expectations in all essential areas of responsibility and the quality of work overall was excellent. Annual objectives were met and can be benchmarked across in both 'What' and 'How' part of the Job."
  },
  {
    label: 'Meets Expectations',
    stars: 2,
    content:
      'Performance consistently met expectations in all essential areas of responsibility, at times possibly exceeding expectations and the quality of work overall was very good. The most critical annual goals were met.'
  },
  {
    label: 'Below Expectation',
    stars: 1,
    content:
      'Performance did not consistently meet expectations – performance failed to meet expectations in one or more essential areas of responsibility and/or one or more of the most critical goals were not met. A performance improvement plan to improve performance must be outlined.'
  }
]

export const initialValues = (impactArea, goal, kpi, drawerAction) => {
  if (kpi || drawerAction.type === 'kpi') {
    return {
      title: kpi?.title,
      tags: kpi?.library_tags?.map((libraryTag) => ({ value: libraryTag.tag.id, label: libraryTag.tag.name }))
    }
  } else if (goal || drawerAction.type === 'goal') {
    return {
      title: goal?.title,
      tags: goal?.library_tags?.map((libraryTag) => ({ value: libraryTag.tag.id, label: libraryTag.tag.name }))
    }
  } else if (impactArea) {
    return {
      title: impactArea.title,
      weightage: impactArea.weightage,
      state: impactArea.state,
      tags: impactArea.tags_data?.map((libraryTag) => ({ value: libraryTag.tag.id, label: libraryTag.tag.name }))
    }
  } else {
    return {
      title: '',
      weightage: '0',
      state: null,
      tags: []
    }
  }
}

export const stateOptions = [
  {
    label: 'Qualitative',
    value: 'qualitative'
  },
  {
    label: 'Quantitative',
    value: 'quantitative'
  }
]

export const formatFormValues = (formValues, impactArea, goal, kpi, type) => {
  formValues.library_tags_attributes = formValues.tags?.map((tag) => ({ tag_name: tag.label }))

  if (kpi || type === 'kpi') {
    const title = formValues.title
    delete formValues.title

    formValues.library_tags_attributes = kpi
      ? getLibraryTagsFormValues(formValues, kpi.library_tags)
      : formValues.library_tags_attributes
    formValues.id = goal.id
    formValues.kpis_attributes = [
      { id: kpi?.id, title: title, library_tags_attributes: formValues.library_tags_attributes }
    ]
    delete formValues.library_tags_attributes

    return formValues
  } else if (goal || type === 'goal') {
    formValues.id = goal?.id
    formValues.library_tags_attributes = getLibraryTagsFormValues(formValues, goal?.library_tags)
  } else if (impactArea) {
    formValues.library_tags_attributes = getLibraryTagsFormValues(formValues, impactArea.tags_data)
  }

  return formValues
}

const getLibraryTagsFormValues = (formValues, prevTags = []) => {
  let selectedTags = formValues.tags?.map((tag) => tag.label)
  if (!selectedTags) return

  let prev_tags = prevTags?.map((tag) => ({ id: tag.id, tag_name: tag.tag.name }))
  let prevselectedTags = prev_tags.map((tag) => tag.tag_name)
  let newTags = selectedTags.filter((val) => !prevselectedTags.includes(val))

  formValues.library_tags_attributes = prev_tags.map((tag) => {
    if (!selectedTags.includes(tag.tag_name)) {
      return { _destroy: true, id: tag.id, tag_name: tag.tag_name }
    } else {
      return { id: tag.id, tag_name: tag.tag_name }
    }
  })

  formValues.library_tags_attributes = formValues.library_tags_attributes.concat(
    newTags.map((tag) => ({ tag_name: tag }))
  )

  return formValues.library_tags_attributes
}

export const reviewPeriodOptions = [
  { label: 'Month', value: '0' },
  { label: 'Quarter', value: '1' },
  { label: 'Bi-Annual', value: '2' },
  { label: 'Annual', value: '3' }
]

export const getCurrentQuarterDate = (date) => {
  const quarter = moment(date).quarter()
  return `1-${quarter === 2 ? '4' : quarter === 3 ? '7' : quarter === 4 ? '10' : quarter}-${getYearFromDate(date)}`
}

export const monthOptions = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
}

export const getQuarterOptions = (type) => {
  const currentYear = getYearFromDate(new Date())
  if (type === '0') {
    return [
      ...Array(24)
        .fill(0)
        .map((_, index) => ({
          label: `${monthOptions[index % 12]} ${index > 11 ? currentYear + 1 : currentYear}`,
          value: `1-${(index % 12) + 1}-${index > 11 ? currentYear + 1 : currentYear}`
        }))
    ]
  } else if (type === '1') {
    return [
      { label: `Q1 ${currentYear}`, value: `1-1-${currentYear}` },
      { label: `Q2 ${currentYear}`, value: `1-4-${currentYear}` },
      { label: `Q3 ${currentYear}`, value: `1-7-${currentYear}` },
      { label: `Q4 ${currentYear}`, value: `1-10-${currentYear}` },
      { label: `Q1 ${currentYear + 1}`, value: `1-1-${currentYear + 1}` },
      { label: `Q2 ${currentYear + 1}`, value: `1-4-${currentYear + 1}` },
      { label: `Q3 ${currentYear + 1}`, value: `1-7-${currentYear + 1}` },
      { label: `Q4 ${currentYear + 1}`, value: `1-10-${currentYear + 1}` }
    ]
  } else if (type === '2') {
    return [
      { label: `Jan - Jun ${currentYear}`, value: `1-1-${currentYear}` },
      { label: `Jul - Dec ${currentYear}`, value: `1-7-${currentYear}` },
      { label: `Jan - Jun ${currentYear + 1}`, value: `1-1-${currentYear + 1}` },
      { label: `Jul - Dec ${currentYear + 1}`, value: `1-7-${currentYear + 1}` }
    ]
  } else {
    return [
      { label: `${currentYear}`, value: `1-1-${currentYear}` },
      { label: `${currentYear + 1}`, value: `1-1-${currentYear + 1}` }
    ]
  }
}

export const getTotalWeightageSum = (impactAreas) => {
  const add = (accumulator, a) => accumulator + a
  return impactAreas.map((impactArea) => impactArea.weightage).reduce(add, 0)
}

export const highgligtSearchedText = (title, searchedText) =>
  title.replace(searchedText, `<span class='highlight'> ${searchedText} </span>`)

const quarterOptions = { 0: 'month', 1: 'quarter', 3: 'year' }

const getPreviousPeriodEnd = (date) => date.subtract(1, 'months').endOf('month').format(TWO_DIGIT_FORMAT)

const isInCurrentPeriod = (currentDate, date, type) => {
  if (type === '2') {
    const biAnnualStart =
      currentDate.month() < 6 ? currentDate.clone().startOf('year') : currentDate.clone().month(6).startOf('month')
    const biAnnualEnd =
      currentDate.month() < 6 ? currentDate.clone().month(5).endOf('month') : currentDate.clone().endOf('year')
    return date.isBetween(biAnnualStart, biAnnualEnd, null, '[]')
  } else if (type !== '3') {
    return currentDate.isSame(date, quarterOptions[type]) && currentDate.isSame(date, 'year')
  } else {
    return currentDate.isSame(date, quarterOptions[type])
  }
}

export const getEffectiveTillDate = (date, type, fromDate, id) => {
  const quarterDate = moment(date, PMS_DATE_FORMAT)
  const currentDate = moment()
  const effectiveFromDate = moment(formatDate(fromDate, TWO_DIGIT_FORMAT))

  const isSameDate = effectiveFromDate.isSame(
    quarterDate,
    type === '0' ? 'month' : type === '1' ? 'quarter' : type === '2' ? 'year' : 'year'
  )
  const isInCurrentPeriodFlag = isInCurrentPeriod(currentDate, quarterDate, type)

  if (!isSameDate && !isInCurrentPeriodFlag) {
    return {
      id,
      effective_till: moment(getPreviousPeriodEnd(quarterDate.clone()), TWO_DIGIT_FORMAT).format(PMS_DATE_FORMAT)
    }
  } else {
    return { id, _destroy: true }
  }
}

export const getDeletedValues = (date, type, fromDate, goalId, setting) => ({
  id: setting.id,
  reviewer_impact_areas_attributes: setting.reviewer_impact_areas.map((area) => ({
    id: area.id,
    impact_area_goal_settings_attributes: area.impact_area.impact_area_goal_settings.map((goal) => ({
      ...(goalId === goal.id ? getEffectiveTillDate(date, type, fromDate, goalId) : { id: goal.id }),
      goal_setting_kpis_attributes: goal.goal.goal_setting_kpis.map((kpi) => ({
        ...(goalId === goal.id ? getEffectiveTillDate(date, type, kpi.effective_from, kpi.id) : { id: kpi.id }),
        ...(!kpi.assign_to_team && {
          user_kpis_attributes: kpi.user_kpis.map((user) => ({
            user_id: user.user.id,
            ...(goalId === goal.id ? getEffectiveTillDate(date, type, user.effective_from, user.id) : { id: user.id })
          }))
        })
      }))
    }))
  }))
})

const getEffectiveFromDate = (date) => {
  const quarterDate = moment(
    moment(formatDate(date, 'D-M-YYYY'), TWO_DIGIT_FORMAT).format(PMS_DATE_FORMAT),
    PMS_DATE_FORMAT
  )
  return moment(quarterDate).format(PMS_DATE_FORMAT)
}

export const getUpdatedUserValues = (date, type, setting, currentKpi, newUsers) => ({
  id: setting.id,
  reviewer_impact_areas_attributes: setting.reviewer_impact_areas.map((area) => ({
    id: area.id,
    impact_area_goal_settings_attributes: area.impact_area.impact_area_goal_settings.map((goal) => ({
      id: goal.id,
      goal_setting_kpis_attributes: goal.goal.goal_setting_kpis.map((kpi) => ({
        id: kpi.id,
        assign_to_team: kpi.assign_to_team,
        ...(!kpi.assign_to_team &&
          (kpi.id === currentKpi.id
            ? {
                user_kpis_attributes: [
                  ...currentKpi.user_kpis.map((user) =>
                    newUsers.includes(user.user.id)
                      ? { id: user.id, user_id: user.user.id }
                      : getEffectiveTillDate(date, type, user.effective_from, user.id)
                  ),
                  ...newUsers
                    .filter((userId) => !currentKpi.user_kpis.some((oldUser) => oldUser.user.id === userId))
                    .map((user) => ({
                      user_id: user,
                      effective_from: getEffectiveFromDate(date)
                    }))
                ]
              }
            : {
                user_kpis_attributes: kpi.user_kpis.map((user) => ({
                  id: user.id,
                  user_id: user.user.id
                }))
              }))
      }))
    }))
  }))
})

const getKpiDates = (prevKpi, quarter) =>
  prevKpi && formatDate(prevKpi?.effective_from, PMS_DATE_FORMAT) !== formatDate(quarter, TWO_DIGIT_FORMAT)

const getUserValues = (kpis, prevKpis, quarter, type) => {
  let newKpis = []
  if (checkArrayPresence(kpis)) {
    kpis.forEach((kpi) => {
      if (
        getKpiDates(
          prevKpis.find((oldKpi) => oldKpi.id === kpi.id),
          quarter
        )
      ) {
        const prevKpi = prevKpis.find((oldKpi) => oldKpi.id === kpi.id)
        newKpis.push({
          kpi_id: prevKpi.kpi.id,
          assign_to_team: prevKpi.assign_to_team,
          ...getEffectiveTillDate(quarter, type, prevKpi.effective_from, prevKpi.id),
          ...(!prevKpi.assign_to_team && {
            user_kpis_attributes: [
              ...(checkArrayPresence(prevKpi.user_kpis)
                ? prevKpi.user_kpis.map((user) => ({
                    user_id: user.user.id,
                    ...getEffectiveTillDate(quarter, type, user.effective_from, user.id)
                  }))
                : [])
            ]
          })
        })
        newKpis.push({
          effective_from: getEffectiveFromDate(quarter),
          kpi_id: kpi.kpi_id,
          assign_to_team: kpi.assign_to === 'team',
          ...(kpi.assign_to !== 'team' && {
            user_kpis_attributes: [
              ...(checkArrayPresence(kpi.users)
                ? kpi.users.map((user) => ({
                    user_id: user,
                    effective_from: getEffectiveFromDate(quarter)
                  }))
                : [])
            ]
          })
        })
      } else {
        newKpis.push({
          ...(kpi.id ? { id: kpi.id } : { effective_from: getEffectiveFromDate(quarter) }),
          kpi_id: kpi.kpi_id,
          assign_to_team: kpi.assign_to === 'team',
          ...(kpi.assign_to !== 'team' && {
            user_kpis_attributes: [
              ...(prevKpis.find((oldKpi) => oldKpi.id === kpi.id)
                ? prevKpis
                    .find((oldKpi) => oldKpi.id === kpi.id)
                    .user_kpis.map((user) =>
                      kpi.users?.includes(user.user.id)
                        ? {
                            id: user.id,
                            user_id: user.user.id
                          }
                        : getEffectiveTillDate(quarter, type, user.effective_from, user.id)
                    )
                : []),
              ...(prevKpis.find((oldKpi) => oldKpi.id === kpi.id)
                ? checkArrayPresence(kpi.users)
                  ? kpi.users
                      .filter(
                        (user) =>
                          !prevKpis
                            .find((oldKpi) => oldKpi.id === kpi.id)
                            ?.user_kpis.some((oldUser) => oldUser.user.id === user)
                      )
                      .map((user) => ({
                        user_id: user,
                        effective_from: getEffectiveFromDate(quarter)
                      }))
                  : []
                : checkArrayPresence(kpi.users)
                  ? kpi.users.map((user) => ({
                      user_id: user,
                      effective_from: getEffectiveFromDate(quarter)
                    }))
                  : [])
            ]
          })
        })
      }
    })
  }
  return newKpis
}

export const getFormSubmitValues = (settings, values, quarter, type) => {
  const currentSetting = settings.setting
  let newValues = {
    id: currentSetting.id,
    reviewer_impact_areas_attributes: currentSetting.reviewer_impact_areas.map((area) => ({
      id: area.id,
      impact_area_goal_settings_attributes: area.impact_area.impact_area_goal_settings.map((goal) => ({
        id: goal.id,
        goal_setting_kpis_attributes: goal.goal.goal_setting_kpis.map((kpi) => ({
          id: kpi.id,
          ...(kpi.assign_to_team
            ? { assign_to_team: kpi.assign_to_team }
            : {
                user_kpis_attributes: kpi.user_kpis.map((user) => ({
                  id: user.id,
                  user_id: user.user.id
                }))
              })
        }))
      }))
    }))
  }
  const areaIndex = newValues.reviewer_impact_areas_attributes.findIndex((area) => area.id === settings.areaId)
  if (settings.action === 'new') {
    const goalIndex = settings.goalId
      ? newValues.reviewer_impact_areas_attributes[areaIndex].impact_area_goal_settings_attributes.findIndex(
          (goal) => goal.id === settings.goalId
        )
      : null
    if (settings.type === 'goal') {
      newValues.reviewer_impact_areas_attributes[areaIndex].impact_area_goal_settings_attributes.push({
        goal_id: values.goal_id,
        weightage: values.weight,
        effective_from: getEffectiveFromDate(quarter),
        goal_setting_kpis_attributes: checkArrayPresence(values.kpis)
          ? values.kpis.map((kpi) => ({
              kpi_id: kpi.kpi_id,
              effective_from: getEffectiveFromDate(quarter),
              assign_to_team: kpi.assign_to === 'team',
              ...(kpi.assign_to !== 'team' && {
                user_kpis_attributes: checkArrayPresence(kpi.users)
                  ? kpi.users.map((user) => ({
                      user_id: user,
                      effective_from: getEffectiveFromDate(quarter)
                    }))
                  : []
              })
            }))
          : []
      })
    } else {
      values.kpis.map((kpi) => {
        newValues.reviewer_impact_areas_attributes[areaIndex].impact_area_goal_settings_attributes[
          goalIndex
        ].goal_setting_kpis_attributes.push({
          kpi_id: kpi.kpi_id,
          effective_from: moment(formatDate(quarter, 'D-M-YYYY'), TWO_DIGIT_FORMAT).format(PMS_DATE_FORMAT),
          assign_to_team: kpi.assign_to === 'team',
          ...(kpi.assign_to !== 'team' && {
            user_kpis_attributes: checkArrayPresence(kpi.users)
              ? kpi.users.map((user) => ({
                  user_id: user,
                  effective_from: moment(formatDate(quarter, 'D-M-YYYY'), TWO_DIGIT_FORMAT).format(PMS_DATE_FORMAT)
                }))
              : []
          })
        })
      })
    }
  } else {
    const goalIndex = newValues.reviewer_impact_areas_attributes[
      areaIndex
    ].impact_area_goal_settings_attributes.findIndex(
      (goal) => goal.id === (settings.type === 'kpi' ? settings.goalId : values.id)
    )
    const mainGoal = currentSetting.reviewer_impact_areas[areaIndex].impact_area.impact_area_goal_settings[goalIndex]
    const removedKpis = mainGoal.goal.goal_setting_kpis.filter(
      (oldKpi) => !values.kpis.some((kpi) => kpi.id === oldKpi.id)
    )
    const prevKpis = mainGoal.goal.goal_setting_kpis.filter((oldKpi) => values.kpis.some((kpi) => kpi.id === oldKpi.id))
    newValues.reviewer_impact_areas_attributes[areaIndex].impact_area_goal_settings_attributes[goalIndex] = {
      id:
        values.id ??
        currentSetting.reviewer_impact_areas[areaIndex].impact_area.impact_area_goal_settings[goalIndex].id,
      ...(settings.type === 'goal' && { goal_id: values.goal_id, weightage: values.weight }),
      goal_setting_kpis_attributes: [
        ...removedKpis.map((kpi) => ({
          ...getEffectiveTillDate(quarter, type, kpi.effective_from, kpi.id),
          assign_to_team: kpi.assign_to_team,
          ...(!kpi.assign_to_team && {
            user_kpis_attributes: checkArrayPresence(kpi.user_kpis)
              ? kpi.user_kpis.map((user) => ({
                  ...getEffectiveTillDate(quarter, type, user.effective_from, user.id)
                }))
              : []
          })
        })),
        ...getUserValues(values.kpis, prevKpis, quarter, type)
      ]
    }
  }
  return newValues
}

export const getSortedReviewsHash = (reviews) => {
  const initialReviewStructure = {
    monthly: [],
    quarterly: [],
    biannual: [],
    annual: []
  }
  return reviews.reduce((acc, current) => {
    const { year, review_type } = current
    if (!acc[year]) {
      acc[year] = { ...initialReviewStructure }
    }

    acc[year][review_type].push(current)

    return acc
  }, {})
}

export const getPeriodInterval = {
  monthly: '0',
  quarterly: '1',
  biannual: '2',
  annual: '3'
}

export const getInitialParams = (settings, setReviewPeriod) => {
  const values = checkArrayPresence(settings) ? settings[0] : null
  const path = window.location.search
  setReviewPeriod(
    path.includes('review_period=')
      ? path.split('review_period=')[1].split('&')[0]
      : values
        ? `${getPeriodInterval[values.review_period]}`
        : '1'
  )

  return {
    quarter: path.includes('quarter=') ? path.split('quarter=')[1].split('&')[0] : getCurrentQuarterDate(new Date()),
    review_period: path.includes('review_period=')
      ? path.split('review_period=')[1].split('&')[0]
      : values
        ? `${getPeriodInterval[values.review_period]}`
        : '1',
    review_setting: path.includes('review_setting=')
      ? path.split('review_setting=')[1].split('&')[0]
      : values
        ? `${values.id}`
        : null
  }
}

const calculateImpactAreaRating = (impactArea) => {
  let sumOfGoalRatingsWrtUser = 0
  let sumOfGoalRatingsWrtReviewer = 0

  impactArea.goals.forEach((goal) => {
    let [userRating, reviewerRating] = calculateGoalRating(goal)
    sumOfGoalRatingsWrtUser += (userRating / 100) * goal.weightage
    sumOfGoalRatingsWrtReviewer += (reviewerRating / 100) * goal.weightage
  })

  return [sumOfGoalRatingsWrtUser, sumOfGoalRatingsWrtReviewer]
}

const calculateKpiRating = (kpi, totalKpisWrtGoal) => {
  let userRating = 0
  let reviewerRating = 0

  if (kpi?.user_kpi_response) {
    userRating = kpi.user_kpi_response.user_rating || 0
    reviewerRating = kpi.user_kpi_response.reviewer_rating || 0
  }

  return [userRating / totalKpisWrtGoal, reviewerRating / totalKpisWrtGoal]
}

const calculateGoalRating = (goal) => {
  let sumOfKpiRatingsWrtUser = 0
  let sumOfKpiRatingsWrtReviewer = 0

  goal.kpis.forEach((kpi) => {
    let [userRating, reviewerRating] = calculateKpiRating(kpi, goal.kpis.length)
    sumOfKpiRatingsWrtUser += userRating
    sumOfKpiRatingsWrtReviewer += reviewerRating
  })

  return [sumOfKpiRatingsWrtUser, sumOfKpiRatingsWrtReviewer]
}

export const getReviewFormInitialValues = (values) => {
  if (Object.keys(values).length === 0) return

  return {
    reviewer_comment_strength: values.strength_wrt_reviewer,
    reviewer_comment_learning: values.learning_wrt_reviewer,
    user_comment_strength: values.strength_wrt_user,
    user_comment_learning: values.learning_wrt_user,
    impact_areas: checkArrayPresence(values.impact_areas)
      ? values.impact_areas.map((area) => {
          const [userImpactAreaRating, reviewerImpactAreaRating] = calculateImpactAreaRating(area)
          return {
            id: area.id,
            title: area.title,
            state: area.state,
            weight: area.weightage,
            total_employee_rating: Math.ceil(userImpactAreaRating),
            total_reviewer_rating: Math.ceil(reviewerImpactAreaRating),
            goals: checkArrayPresence(area.goals)
              ? area.goals.map((goal) => {
                  const [userGoalRating, reviewerGoalRating] = calculateGoalRating(goal)
                  return {
                    id: goal.id,
                    title: goal.title,
                    weight: goal.weightage,
                    total_employee_rating: Math.ceil(userGoalRating),
                    total_reviewer_rating: Math.ceil(reviewerGoalRating),
                    kpis:
                      checkArrayPresence(goal.kpis) && goal.kpis[0]
                        ? goal.kpis.map((kpi) => ({
                            id: kpi.review_sync_user_kpi_id,
                            response_id: kpi.user_kpi_response?.id,
                            title: kpi.title,
                            employee_rating: kpi.user_kpi_response?.user_rating,
                            reviewer_rating: kpi.user_kpi_response?.reviewer_rating,
                            comments: {
                              unread_count: 0,
                              messages: checkArrayPresence(kpi.user_kpi_response?.comments)
                                ? kpi.user_kpi_response?.comments.map((message) => ({
                                    id: message.id,
                                    time: message.created_at,
                                    read: true,
                                    text: message.comment,
                                    attachments: message.comment_attachments,
                                    user: {
                                      id: message.user_id,
                                      name: message.username,
                                      avatar: message.avatar
                                    }
                                  }))
                                : []
                            }
                          }))
                        : []
                  }
                })
              : []
          }
        })
      : []
  }
}

export const getUserReviewSubmitValues = (settings, values, quarter) => {
  const currentSetting = settings.setting
  let newValues = {
    id: currentSetting.review_setting_id,
    reviewer_impact_areas_attributes: [
      {
        id: currentSetting.id,
        impact_area_goal_settings_attributes: currentSetting.goals.map((goal) => ({
          id: goal.id,
          goal_setting_kpis_attributes: goal.kpis.map((kpi) => ({
            id: kpi.id
          }))
        }))
      }
    ]
  }
  if (settings.type === 'goal') {
    newValues.reviewer_impact_areas_attributes[0].impact_area_goal_settings_attributes.push({
      goal_id: values.goal_id,
      weightage: values.weight,
      effective_from: getEffectiveFromDate(quarter),
      goal_setting_kpis_attributes: checkArrayPresence(values.kpis)
        ? values.kpis.map((kpi) => ({
            kpi_id: kpi.kpi_id,
            effective_from: getEffectiveFromDate(quarter),
            assign_to_team: kpi.assign_to === 'team',
            ...(kpi.assign_to !== 'team' && {
              user_kpis_attributes: checkArrayPresence(kpi.users)
                ? kpi.users.map((user) => ({
                    user_id: user,
                    effective_from: getEffectiveFromDate(quarter)
                  }))
                : []
            })
          }))
        : []
    })
  } else {
    const goalIndex = newValues.reviewer_impact_areas_attributes[0].impact_area_goal_settings_attributes.findIndex(
      (goal) => goal.id === settings.goalId
    )
    values.kpis.map((kpi) => {
      newValues.reviewer_impact_areas_attributes[0].impact_area_goal_settings_attributes[
        goalIndex
      ].goal_setting_kpis_attributes.push({
        kpi_id: kpi.kpi_id,
        effective_from: getEffectiveFromDate(quarter),
        assign_to_team: kpi.assign_to === 'team',
        ...(kpi.assign_to !== 'team' && {
          user_kpis_attributes: checkArrayPresence(kpi.users)
            ? kpi.users.map((user) => ({
                user_id: user,
                effective_from: getEffectiveFromDate(quarter)
              }))
            : []
        })
      })
    })
  }
  return newValues
}

const formatFileSize = (bytes) => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Bytes'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + units[i]
}

export const renderChatAttachments = (attachments) =>
  attachments.map((attachment) => (
    <div key={attachment.url ? attachment.url : attachment.media} className="attachment-block">
      <a
        href={attachment.url ? attachment.url : attachment.media}
        target="_blank"
        rel="noreferrer"
        className="d-flex gp-10">
        {fileAttachmentThumbnails(attachment, 'attachment-size')}
        {attachment.name && (
          <div className="flex-column align-items-start justify-space-between">
            <Typography variant="text-sm" type="medium" color="gray-700">
              {formatLongText(attachment.name, 200)}
            </Typography>
            <Typography variant="text-sm" color="gray-500">
              {formatFileSize(attachment.size)}
            </Typography>
          </div>
        )}
      </a>
    </div>
  ))

export const PmsCustomTooltipClass = {
  tooltip: {
    sx: {
      color: darkGrayColor,
      backgroundColor: whiteChipColor
    }
  },
  arrow: {
    sx: {
      color: whiteChipColor
    }
  }
}
