import { useCallback, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { debounce } from 'lodash'
import { Avatar, Stack, Tooltip, Typography } from 'maple-storybook'
import hash from 'object-hash'

import { AvatarWithLabelOption, CustomCheckboxWrapper, Danger } from 'components'
import { avatarImageProps, checkArrayPresence, invalidTextLength, validTextLength } from 'helpers/applicationHelper'
import { getUserOnBoardingID } from 'helpers/userHelper'

import { checkAllOption, MenuList, ValueContainer } from './SelectWrapperHelper'
import { selectWrapperStyle } from './selectWrapperStyle'
import useSelectUser from './useSelectUser'

import './style.scss'

const SelectWrapper = (props) => {
  const { options } = props
  const [availableOptions, setAvailableOptions] = useState([])
  const [optionValueMap, setOptionValueMap] = useState({})
  const [results, handleSearchTermChange] = useSelectUser()
  let selectedValue = []
  let multiple = props.noneValueLabel

  let valueToUse = props.selectedValue || props.input?.value
  if (valueToUse === 1 && props.noneValueLabel) multiple = false

  const filters =
    (props.userSearchable
      ? { ...props.filters, user_statuses: props.filters?.onboarding_users ? [] : ['onboarded'] }
      : props.filters) || {}
  const [filtersHash, setFiltersHash] = useState(hash(filters))
  const [displayHint, setDisplayHint] = useState(false)
  const [newValue, setNewValue] = useState('')

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      {props.selectProps.dropDownIcon && props.selectProps.dropDownIcon}
    </components.DropdownIndicator>
  )

  useEffect(() => {
    setOptionValueMap(
      options?.reduce((map, option) => {
        map[option.value] = option
        return map
      }, {})
    )
    if (props.userSearchable && results[filtersHash]) {
      setOptionValueMap(
        results[filtersHash].reduce((map, option) => {
          map[option.value] = option
          return map
        }, [])
      )
    }
    checkArrayPresence(options) &&
      setAvailableOptions(props.allCheck ? [{ label: 'All', value: '*' }, ...options] : options)
  }, [options, results, filtersHash, props.allCheck])

  const filters_key = hash(filters)

  useEffect(() => {
    setFiltersHash(filters_key)
    props.userSearchable &&
      (valueToUse instanceof Array && checkIfSearchRequired(valueToUse)
        ? valueToUse.forEach((val) => handleSearchTermChange(val, filters, filtersHash))
        : handleSearchTermChange(valueToUse, filters, filtersHash))
  }, [filters_key, valueToUse])

  const checkIfSearchRequired = (values) => {
    if (!optionValueMap) return true

    if (values.length > 0) {
      values.forEach((val) => {
        if (optionValueMap[val] !== val) return false
      })
    } else {
      return optionValueMap[values] == values
    }
  }

  const setName = debounce((value) => {
    setNewValue(value)
  }, 500)

  const handleInputChange = useCallback(
    (e, filters, filtersHash) => {
      if (props.optionAdditive) setName(e)
      else if (props.userSearchable) {
        if (typeof e === 'string' && isNaN(parseInt(e)) && invalidTextLength(e)) setDisplayHint(true)
        else if (validTextLength(e, displayHint)) setDisplayHint(false)

        return handleSearchTermChange(e, filters, filtersHash)
      }
    },
    [props.userSearchable, displayHint, handleSearchTermChange, setName, props.optionAdditive]
  )

  const handleOnChange = (value) => {
    props.onChange && props.onChange(value)
    if (props.isMulti || multiple) {
      if (checkAllOption(value)) {
        const newValues =
          value.length === options.length && checkAllOption(selectedValue)
            ? value?.filter((value) => value?.value !== '*')
            : options
        props.input.onChange(newValues.map((obj) => obj?.value))
      } else {
        let isMultiSelect = false
        let multiValues = []
        value?.forEach((value) => {
          if (value.label === 'Not Applicable' && props.noneValueLabel) {
            isMultiSelect = true
          } else {
            multiValues.push(value?.value)
            isMultiSelect = false
          }
        })

        props.input?.onChange(
          checkAllOption(selectedValue) ? [] : isMultiSelect ? availableOptions[0].value : multiValues
        )
      }
    } else {
      value == null ? props.input?.onChange(value) : props.input?.onChange(value?.value)
    }
  }

  const handleOnBlur = () => {
    props.onBlur && props.onBlur(props.input.value)
    props.input?.onBlur && props.input.onBlur(props.input.value)
  }

  const handleOnOpen = () => props.onOpen && props.onOpen()

  if (optionValueMap?.length > 0 || availableOptions?.length > 0) {
    if (valueToUse) {
      if (valueToUse instanceof Array) {
        selectedValue = valueToUse.map((o) => optionValueMap && optionValueMap[typeof o === 'object' ? o.value : o])
        if (props.allCheck && selectedValue.length === options.length) {
          selectedValue = [{ label: 'All', value: '*' }, ...selectedValue]
        }
      } else {
        selectedValue = optionValueMap[valueToUse]
      }
    } else {
      selectedValue = null
    }
  }

  return (
    <Tooltip
      placement="top-end"
      title={`${displayHint && props.userSearchable ? 'Enter at least 3 characters' : ''}`}
      arrow>
      <div
        className={`flex-column ${props.meta && props.input ? 'select-input-container-style' : ''} ${
          props.inputContainerStyle
        }`}>
        {props.heading && (
          <Stack direction="row" sx={{ alignItems: 'center' }} className="text-heading">
            <Typography variant="text-sm" color="gray-700">
              {props.heading}
            </Typography>
            {props.customComponent && props.customComponent}
          </Stack>
        )}
        <Select
          {...props}
          {...props.input}
          id={props.id}
          inputId={props.input?.name}
          styles={{
            ...props.style,
            ...selectWrapperStyle(props)
          }}
          formatOptionLabel={(option) => (
            <span className="d-flex align-items-center multi-option">
              {availableOptions?.length < 50 && props.isMulti && (
                <CustomCheckboxWrapper
                  input={{
                    value:
                      selectedValue && Array.isArray(selectedValue)
                        ? selectedValue.some((selectedOption) => selectedOption?.value === option.value)
                        : selectedValue?.value === option.value
                  }}
                />
              )}
              {option.avatar && (
                <span className="d-flex align-items-center">
                  <Avatar
                    src={option.avatar}
                    style={{ height: 25, width: 25, marginRight: 3 }}
                    imgProps={avatarImageProps()}
                  />
                </span>
              )}
              {option.label}&nbsp;
              {(option.avatar && `(${getUserOnBoardingID(option.value)})`) ||
                (option.short_name &&
                  `(${option.department_short_name ? option.department_short_name : option.short_name})`)}
              {props.threadView && `(TID-${option.value})`}
            </span>
          )}
          name={props.input?.name || props.name}
          optionClassName="needsclick"
          blurInputOnSelect={false}
          classNamePrefix="react-select"
          optionRenderer={(option) => (
            <AvatarWithLabelOption avatar={option.avatar} label={option.label} id={option.value} />
          )}
          isMulti={props.isMulti || multiple}
          value={selectedValue || ''}
          components={{ ValueContainer, MenuList, DropdownIndicator }}
          hideSelectedOptions={false}
          closeMenuOnSelect={!props.isMulti}
          placeholder={props.userSearchable && !props.placeholder ? 'Search by (name/id)' : props.placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onOpen={handleOnOpen}
          onMenuClose={() => setDisplayHint(false)}
          noOptionsMessage={() =>
            props.optionAdditive ? (
              <>
                No Options
                <span className="blue-color" onClick={() => props.handleSearchFilterChange(newValue)}>
                  (Add New)
                </span>
              </>
            ) : (
              <>No options</>
            )
          }
          isClearable={props.isClearable}
          isDisabled={props.disabled}
          isOptionDisabled={(option) => option.isOptionDisabled}
          options={props.userSearchable ? (results[filtersHash] ? results[filtersHash] : []) : availableOptions}
          onInputChange={(e) => handleInputChange(e, filters, filtersHash)}
        />

        {props.meta?.touched && props.meta?.error && <Danger>{props.meta.error}</Danger>}
        {props.helpText ? <small style={{ color: 'blue' }}>{props.helpText}</small> : null}
      </div>
    </Tooltip>
  )
}

export default SelectWrapper
