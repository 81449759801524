import {
  CHANGE_REVIEWER_SUCCESS,
  CREATE_IMPACT_AREA_SUCCESS,
  CREATE_REVIEW_CONFIG_SUCCESS,
  DELETE_IMPACT_AREA_SUCCESS,
  DELETE_REVIEW_CONFIG_SUCCESS,
  FETCH_GOAL_AND_KPI_SUCCESS,
  FETCH_PMS_REVIEW_SETTINGS_SUCCESS,
  FETCH_REVIEW_CONFIG_SUCCESS,
  FETCH_REVIEW_CONFIGURATION_SUCCESS,
  FETCH_REVIEW_DETAIL_TIMELINE_SUCCESS,
  FETCH_REVIEW_IMPACT_AREAS_SUCCESS,
  FETCH_REVIEW_SYNC_DETAIL_SUCCESS,
  FETCH_SELECT_GOAL_KPI_SUCCESS,
  FETCH_TAGS_SUCCESS,
  FETCH_TEAMMATES_REVIEWS_SUCCESS,
  FETCH_USER_DETAILED_REVIEW_SUCCESS,
  FETCH_USER_REVIEW_SYNCS_SUCCESS,
  SHOW_PMS_LOADER,
  SUBMIT_MANAGER_REVIEW_FEEDBACK_SUCCESS,
  UPDATE_IMPACT_AREA_SUCCESS,
  UPDATE_REVIEW_CONFIG_SUCCESS,
  UPDATE_REVIEW_SYNC_SUCCESS,
  UPDATE_SETTING_IMPACT_AREA_SUCCESS
} from 'actions/actionTypes'
import { getSortedReviewsHash } from 'helpers/pmsHelper'

import initialState from './initialState'

export const pms = (state = initialState.pms, action) => {
  let reviewConfigurations
  let index
  let goalIndex
  let impactAreas
  let weightage
  let updatedReviewSyncDetails
  let updatedReviewDetails

  switch (action.type) {
    case SHOW_PMS_LOADER:
      return { ...state, loader: true }

    case FETCH_REVIEW_CONFIGURATION_SUCCESS:
      return { ...state, reviewConfigurations: action.payload, loader: false }

    case FETCH_REVIEW_CONFIG_SUCCESS:
      return { ...state, reviewConfig: action.payload }

    case CREATE_REVIEW_CONFIG_SUCCESS: {
      if (action.payload.revisions) {
        reviewConfigurations = [...state.reviewConfigurations]
        action.payload.revisions.forEach((revision) => {
          reviewConfigurations.forEach((config, index) => {
            if (config.id === revision.parent_id)
              reviewConfigurations[index] = {
                ...config,
                ...revision
              }
          })
        })

        reviewConfigurations.push(action.payload.setting)
        return {
          ...state,
          reviewConfigurations: reviewConfigurations
        }
      } else return { ...state, reviewConfigurations: [...state.reviewConfigurations, action.payload] }
    }

    case UPDATE_REVIEW_CONFIG_SUCCESS: {
      if (action.payload.revisions) {
        reviewConfigurations = [...state.reviewConfigurations]
        action.payload.revisions.forEach((revision) => {
          reviewConfigurations.forEach((config, index) => {
            if (config.id === revision.parent_id)
              reviewConfigurations[index] = {
                ...config,
                ...revision
              }
          })
        })
        index = state.reviewConfigurations.findIndex(
          (reviewConfig) => reviewConfig.id === action.payload.setting.parent_id
        )
        if (index !== -1) {
          reviewConfigurations[index] = action.payload.setting
        }
        return {
          ...state,
          reviewConfigurations: reviewConfigurations
        }
      } else {
        reviewConfigurations = [...state.reviewConfigurations]
        index = state.reviewConfigurations.findIndex((reviewConfig) =>
          reviewConfig.id === action.payload.parent_id ? action.payload.parent_id : action.payload.id
        )
        if (index !== -1) {
          reviewConfigurations[index] = action.payload
        }
        return {
          ...state,
          reviewConfigurations: reviewConfigurations
        }
      }
    }
    case DELETE_REVIEW_CONFIG_SUCCESS: {
      reviewConfigurations = [...state.reviewConfigurations]
      index = state.reviewConfigurations.findIndex((reviewConfig) => reviewConfig.id === action.payload.id)
      if (index !== -1) {
        reviewConfigurations.splice(index, 1)
      }
      return {
        ...state,
        reviewConfigurations: reviewConfigurations
      }
    }

    case FETCH_GOAL_AND_KPI_SUCCESS:
      return { ...state, goalAndKpis: action.payload }

    case FETCH_TAGS_SUCCESS:
      return { ...state, tags: action.payload }

    case FETCH_REVIEW_IMPACT_AREAS_SUCCESS:
      return { ...state, impactAreas: action.payload.impact_areas, totalWeightage: action.payload.total_weightage }

    case FETCH_PMS_REVIEW_SETTINGS_SUCCESS:
      return { ...state, selectReviewSettings: action.payload }

    case UPDATE_SETTING_IMPACT_AREA_SUCCESS:
      if (action.flag.action === 'delete') {
        if (action.flag.type === 'kpi') {
          reviewConfigurations = [...state.goalAndKpis]
          index = state.goalAndKpis.findIndex((setting) => setting.id === action.flag.settingId)
          impactAreas = reviewConfigurations[index].reviewer_impact_areas.findIndex(
            (area) => area.id === action.flag.areaId
          )
          goalIndex = reviewConfigurations[index].reviewer_impact_areas[
            impactAreas
          ].impact_area.impact_area_goal_settings.findIndex((goal) => goal.id === action.flag.goalId)
          reviewConfigurations[index].reviewer_impact_areas[impactAreas].impact_area.impact_area_goal_settings[
            goalIndex
          ].goal.goal_setting_kpis = reviewConfigurations[index].reviewer_impact_areas[
            impactAreas
          ].impact_area.impact_area_goal_settings[goalIndex].goal.goal_setting_kpis.filter(
            (kpi) => kpi.id !== action.payload.id
          )
          return {
            ...state,
            goalAndKpis: [...state.goalAndKpis.filter((goal) => goal.id !== action.payload.id), action.payload]
          }
        } else {
          reviewConfigurations = [...state.goalAndKpis]
          index = state.goalAndKpis.findIndex((setting) => setting.id === action.flag.settingId)
          reviewConfigurations[index].reviewer_impact_areas = action.payload.reviewer_impact_areas
          return { ...state, goalAndKpis: reviewConfigurations }
        }
      } else {
        return {
          ...state,
          goalAndKpis: [action.payload, ...state.goalAndKpis.filter((setting) => setting.id !== action.payload.id)]
        }
      }

    case FETCH_SELECT_GOAL_KPI_SUCCESS:
      return {
        ...state,
        selectGoalKpis: { ...state.selectGoalKpis, [action.onlyGoals ? 'goals' : 'kpis']: action.payload }
      }

    case CREATE_IMPACT_AREA_SUCCESS:
      return {
        ...state,
        impactAreas: [...state.impactAreas, action.payload],
        totalWeightage: state.totalWeightage + action.payload.weightage
      }
    case DELETE_IMPACT_AREA_SUCCESS:
      return {
        ...state,
        impactAreas: [...state.impactAreas.filter((impactArea) => impactArea.id !== action.payload.id)],
        totalWeightage: state.totalWeightage - action.payload.weightage
      }
    case UPDATE_IMPACT_AREA_SUCCESS:
      impactAreas = [...state.impactAreas]
      index = state.impactAreas.findIndex((impactArea) => impactArea.id === action.payload.id)
      weightage = state.totalWeightage - impactAreas[index].weightage

      if (index !== -1) {
        weightage = state.totalWeightage - impactAreas[index].weightage
        impactAreas[index] = action.payload
      }

      return {
        ...state,
        impactAreas: impactAreas,
        totalWeightage: weightage + action.payload.weightage
      }

    case FETCH_TEAMMATES_REVIEWS_SUCCESS:
      return { ...state, teammatesReviews: action.payload, loader: false }

    case CHANGE_REVIEWER_SUCCESS: {
      let reviews = [...state.teammatesReviews.reviews]
      index = reviews.findIndex((user) => user.id === action.payload.id)
      if (index !== -1) {
        reviews[index].reviewer = action.payload.reviewer
      }
      return {
        ...state,
        teammatesReviews: { reviews: [...reviews], review_stats: [...state.teammatesReviews.review_stats] },
        loader: false
      }
    }

    case FETCH_REVIEW_DETAIL_TIMELINE_SUCCESS:
      return {
        ...state,
        timelineDetails: {
          user: action.payload.timeline.user,
          last_review: action.payload.timeline.last_review,
          reviews: getSortedReviewsHash(action.payload.timeline.reviews)
        }
      }

    case FETCH_USER_REVIEW_SYNCS_SUCCESS:
      return { ...state, reviewSync: action.payload }

    case FETCH_REVIEW_SYNC_DETAIL_SUCCESS:
      return { ...state, reviewSyncDetails: action.payload }

    // PMS REQUIRE INTEGRATION
    case FETCH_USER_DETAILED_REVIEW_SUCCESS:
      return { ...state, userReviewDetails: action.payload }

    case SUBMIT_MANAGER_REVIEW_FEEDBACK_SUCCESS:
      updatedReviewSyncDetails = state.reviewSyncDetails
      updatedReviewSyncDetails.total_rating = action.payload.sync_rating

      updatedReviewSyncDetails.impactArea = state.reviewSyncDetails.impact_areas.forEach((impact_area) => {
        impact_area.goals.forEach((goal) => {
          goal.kpis.forEach((kpi) => {
            if (kpi.review_sync_user_kpi_id === action.payload.review_sync_user_kpi_id) {
              kpi.user_kpi_response = action.payload
            }
          })
        })
      })

      return { ...state, reviewSyncDetails: updatedReviewSyncDetails }
    case UPDATE_REVIEW_SYNC_SUCCESS:
      updatedReviewSyncDetails = state.reviewSyncDetails
      updatedReviewSyncDetails.status = action.payload.status
      updatedReviewSyncDetails.complete_date = action.payload.complete_date
      updatedReviewDetails = [...state.reviewSync.review_syncs]

      updatedReviewDetails.forEach((sync) => {
        if (sync?.id === action.payload.id) {
          sync.status = action.payload.status
        }
      })

      return {
        ...state,
        reviewSync: { ...state.reviewSync, review_syncs: updatedReviewDetails },
        reviewSyncDetails: updatedReviewSyncDetails
      }
    default:
      return state
  }
}
