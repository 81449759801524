const initialState = {
  roles: {
    roles: [],
    role: {},
    roleUpdated: false,
    roleLoader: false
  },
  departments: {
    departments: [],
    departmentFilters: [],
    selectDepartments: [],
    departmentsList: []
  },
  questions: {
    questions: [],
    questionById: [],
    questionsLoader: false
  },
  reduxTokenAuth: {
    currentUser: {
      isLoading: false,
      isSignedIn: false,
      hasVerificationBeenAttempted: false,
      attributes: {}
    }
  },
  pms: {
    reviewConfigurations: [],
    reviewConfig: {},
    loader: false,
    teammatesReviews: {
      reviews: [],
      review_stats: []
    },
    selectGoalKpis: {
      goals: [],
      kpis: []
    },
    timelineDetails: {
      reviews: {},
      last_review: {},
      user: {}
    },
    impactAreas: [],
    totalWeightage: 0,
    tags: [],
    goalAndKpis: [],
    reviewSync: {},
    reviewSyncDetails: {},
    selectReviewSettings: [],
    userReviewDetails: {
      sync_details: [
        { date: '05/03/2024', status: 'completed' },
        { date: '05/08/2024', status: 'upcoming' }
      ],
      sync_date: '05/05/2024',
      status: 'in_progress',
      achieved_score: 80,
      performance_metrics: [
        {
          id: 1,
          review_setting_id: 1,
          review_period: 'quarterly',
          impact_area: {
            id: 1,
            title: 'Efficency',
            weightage: 40,
            total_employee_rating: 65,
            total_reviewer_rating: 75,
            state: 'qualitative',
            impact_area_goal_settings: [
              {
                id: 1,
                weightage: 50,
                total_employee_rating: 65,
                total_reviewer_rating: 75,
                goal: {
                  id: 1,
                  title: 'PR Review',
                  goal_setting_kpis: [
                    {
                      id: 3,
                      impact_area_goal_setting_id: 1,
                      employee_rating: 70,
                      reviewer_rating: 80,
                      kpi: {
                        id: 1,
                        title: 'PR Review'
                      },
                      comments: {
                        unread_count: 3,
                        messages: [
                          {
                            id: 1,
                            read: true,
                            time: new Date(),
                            attachments: [
                              {
                                name: '01_Picture_723b185609.png',
                                media:
                                  'http://localhost:3000/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                path: '/Users/dev/Documents/MAPLE_HRMS/hrms/public/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                size: 26479,
                                type: 'png'
                              }
                            ],
                            user: {
                              id: 1,
                              name: 'Jawad Firdous',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 2,
                            read: true,
                            time: new Date(),
                            text: 'SECOND TEXT',
                            user: {
                              id: 2,
                              name: 'Zia Ul Rehman',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 3,
                            read: true,
                            time: new Date(),
                            attachments: [
                              {
                                name: '01_Picture_723b185609.png',
                                media:
                                  'http://localhost:3000/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                path: '/Users/dev/Documents/MAPLE_HRMS/hrms/public/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                size: 26479,
                                type: 'png'
                              }
                            ],
                            user: {
                              id: 2,
                              name: 'Zia Ul Rehman',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 4,
                            read: true,
                            time: new Date(),
                            text: 'Fourth TEXT',
                            user: {
                              id: 1,
                              name: 'Jawad Firdous',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                id: 1,
                weightage: 50,
                total_employee_rating: 65,
                total_reviewer_rating: 75,
                goal: {
                  id: 1,
                  title: 'PR Review',
                  goal_setting_kpis: [
                    {
                      id: 3,
                      impact_area_goal_setting_id: 1,
                      employee_rating: 70,
                      reviewer_rating: 80,
                      kpi: {
                        id: 1,
                        title: 'PR Review'
                      },
                      comments: {
                        unread_count: 3,
                        messages: [
                          {
                            id: 1,
                            read: true,
                            time: new Date(),
                            attachments: [
                              {
                                name: '01_Picture_723b185609.png',
                                media:
                                  'http://localhost:3000/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                path: '/Users/dev/Documents/MAPLE_HRMS/hrms/public/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                size: 26479,
                                type: 'png'
                              }
                            ],
                            user: {
                              id: 1,
                              name: 'Jawad Firdous',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 2,
                            read: true,
                            time: new Date(),
                            text: 'SECOND TEXT',
                            user: {
                              id: 2,
                              name: 'Zia Ul Rehman',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 3,
                            read: true,
                            time: new Date(),
                            attachments: [
                              {
                                name: '01_Picture_723b185609.png',
                                media:
                                  'http://localhost:3000/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                path: '/Users/dev/Documents/MAPLE_HRMS/hrms/public/tenant/1/uploads/attachment/file/1/01_Picture_723b185609.png',
                                size: 26479,
                                type: 'png'
                              }
                            ],
                            user: {
                              id: 2,
                              name: 'Zia Ul Rehman',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          },
                          {
                            id: 4,
                            read: true,
                            time: new Date(),
                            text: 'Fourth TEXT',
                            user: {
                              id: 1,
                              name: 'Jawad Firdous',
                              avatar: 'http://localhost:3000/avatar.png'
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    }
  },
  modals: {
    modals: []
  },
  subscriptionsReports: {
    reports: {
      availed_subscription: [],
      record_stats: {
        total_subscriptions: 0,
        redeemed_subscriptions: 0,
        average_subscription: 0,
        subscriptions_percentage: 0,
        subscriptions_status: '',
        subscription: '',
        peek_hours: '',
        buffer: 0
      }
    },
    peakHoursData: [],
    redeemSubscriptionData: [],
    buffer: []
  },
  users: {
    dashboard: {},
    teamAnalytics: {},
    allUserProfiles: {},
    allUsers: [],
    offboardingUsers: [],
    searchableUsers: {},
    selectUsers: [],
    reportingToUsers: [],
    userCreateFormErrors: null,
    resetPasswordSuccess: false, //related to welcome page
    resetPasswordMailStatus: {
      mailSent: false,
      errors: []
    },
    clearanceData: {},
    profileUpdated: false,
    fieldsSubmitSuccess: false,
    blockedUsers: [],
    userSubordinates: [],
    userLoader: false,
    organoChartLoader: false,
    csvAttributes: [],
    headerData: null,
    todayFeedbackPending: false,
    employmentTypes: {},
    csvAttributesForBulkUser: [],
    csvErrors: [],
    departmentUsers: [],
    verificationUsers: [],
    onboardingUsers: [],
    creationLogs: [],
    rawUser: {},
    exitReasons: {
      exitReasons: []
    }
  },
  tickets: {
    assignedTickets: [],
    categories: [],
    createdTickets: [],
    searchedTickets: [],
    ticket: {},
    ticketComments: {},
    csvData: [],
    csvAttributes: [],
    ticketFetched: false,
    ticketLoader: false,
    ticketStats: {},
    assignableUsers: [],
    ticketCategory: {},
    changeLogs: [],
    actions: [],
    scheduleEmails: []
  },
  leaves: {
    allLeavesSummary: [], //all user's leaves summary
    currentUserLeaves: [], // current user's leaves
    currentUserLeaveApprovals: [], // leaves current user can approve
    allUserLeavesSummary: [], // number of leaves by user for leave status form
    allLeavesLifecycle: [], // lifecycle of leaves
    allUsersLeavesHistory: [], // all leaves by all users
    allManuallyAdjustedLeaves: [], // all leaves manually added/deleted by HR
    allHolidays: [], //all annual holidays
    upcomingHolidays: [], //upcoming public Holidays
    rangeLeaves: [],
    leaveTypes: [],
    leaveType: {},
    employmentTypes: [],
    pageSize: 50,
    leaveLoader: false,
    leaveTypeDetail: {}
  },
  leavePolicies: {
    leavePolicies: [],
    leavePolicyByYear: [],
    sandwichPolicies: [],
    currentUserLeavePoliciesQuota: {
      annual: 0,
      sick: 0,
      compensation: 0
    },
    autoLeaveApplyRules: [],
    autoLeaveApplyRule: {},
    autoLeaveLoader: false
  },
  items: {
    allItems: [],
    vendorItems: [],
    clonedItem: 0,
    userItems: [],
    repairItems: [],
    resellItems: [],
    pendingAcceptanceAllowed: false,
    allItemProfiles: [],
    itemUpdated: false,
    archivedItems: [],
    itemLogs: [],
    freeItemsCount: 0,
    pendingItemsCount: 0,
    itemCreated: false,
    freeItems: [],
    pageSize: 50,
    loadingItems: false,
    ItemsNames: [],
    itemRepairHistory: [],
    statsLastUpdatedAt: '',
    graphData: {
      categoryGraph: [],
      subCategoriesGraph: [],
      statsGraph: {
        locations: []
      },
      assetsGraph: []
    },
    overviewGraphFilters: {},
    csvAttributesForBulkItem: [],
    csvErrors: [],
    csvUploadStatus: false
  },
  categories: {
    categories: [],
    csvAttributes: []
  },
  vendors: {
    vendors: [],
    selectVendors: [],
    vendorsCsvAttributes: []
  },
  offices: {
    offices: [],
    selectOffices: []
  },
  itemAllocations: {
    allocations: [],
    itemAllocationStats: []
  },
  dnars: {
    dnarsBank: [],
    subordinates: [],
    fundHistory: [],
    receiveHistory: [],
    categories: [],
    redeemPolicies: [],
    pointsAllocationRequested: false,
    personalDnarsHistory: [],
    personalDnarsStats: []
  },
  adminPanel: {
    formValues: {},
    authToken: undefined,
    authTokenId: undefined,
    tokenValidated: false,
    mintingRequestCreated: false,
    mintingHistory: [],
    rollbackHistory: [],
    userTransactions: [],
    centralDnarsBank: [],
    activeTransactions: [],
    processedTransactions: [],
    transferRequestCreated: false,
    allUserDnars: []
  },
  projects: {
    projects: [],
    project: {},
    projectTimeline: [],
    selectTimelineProjects: [],
    selectTimelinePseudoProfiles: [],
    selectProjects: [],
    selectPseudoProfiles: [],
    projectListLoader: false,
    projectCreated: false,
    projectUpdated: false,
    userProject: [],
    userProjects: [],
    userProjectsHistory: [],
    usersProjects: [],
    projectHistory: [],
    projectUsers: [],
    projectEligibleUsers: [],
    projectModuleUsers: [],
    userProjectsForClaims: [],
    pseudoProfiles: [],
    pseudoDesignations: [],
    SampleCsvData: [],
    monthlyInvoice: [],
    csvAttributes: [],
    memberCsvData: [],
    memberCsvAttributes: [],
    csvErrors: [],
    threadPercentageHistory: [],
    projectServices: []
  },
  permissions: {
    allPermissions: {},
    defaultPermissions: {},
    userPermissions: []
  },
  errors: {
    appError: false
  },
  sidebar: {
    open: false,
    toggle: false
  },
  notifications: {
    allNotifications: { notifications: [], unread_count: 0 },
    unreadNotifications: { notifications: [], unread_count: 0 },
    notificationsWithFiles: { notifications: [], unread_count: 0 },
    pageNumber: 1,
    callInProgress: ''
  },
  titles: {
    allTitles: [],
    titleLogs: []
  },
  locations: {
    locations: []
  },
  positions: {
    experiencePositions: []
  },
  companies: {
    experienceCompanies: []
  },
  businessUnits: [],
  currentCompany: {},
  formSubmission: {
    submission: false
  },
  feedbacks: {
    allFeedbacks: []
  },
  technologies: {
    technologies: []
  },
  teamMembers: {
    teamMembers: [],
    csvAttributes: []
  },
  industries: {
    industries: []
  },
  threadType: {
    threadTypes: []
  },
  timeTracker: {
    userIds: [],
    departmentIds: [],
    titlesList: []
  },
  universities: {
    universities: []
  },
  reports: {
    overviewReport: [],
    workflowReportTableData: [],
    workflowReportGraphData: [],
    workflowStatsUpdatedAt: {},
    workflowReportOverviewData: {},
    leaveReport: [],
    ticketReport: [],
    feedbackReport: [],
    totalUsers: 0,
    totalOnboardedUsers: 0,
    totalOffboardedUsers: 0,
    customReportStats: {},
    downloadedCustomReport: {},
    peopleCustomReports: [],
    reviewReports: {},
    ticketCards: {},
    ticketDepartments: [],
    ticketCategories: [],
    ticketAssignee: [],
    ticketStatsUpdatedAt: {}
  },
  settings: {
    projectPolicies: [],
    ticketPolicies: [],
    HROpsPolicies: [],
    dnarsPolicies: [],
    claimsPolicies: [],
    inventoryPolicies: [],
    dynamicForms: [],
    dynamicForm: {},
    dynamicFormDraft: {},
    dynamicFormCSV: {},
    dynamicFormResponse: {
      users: [],
      responses: []
    },
    dynamicFormStatsUpdatedAt: '',
    dynamicFormsLoader: false,
    editPolicyId: '',
    claimDependencies: [],
    reviewSettings: [],
    reviewSetting: {},
    announcemetSettings: [],
    announcemetSetting: {},
    slackChannels: [],
    loader: false,
    inviteSettings: [],
    companyFeaturedPages: [],
    companyFeaturedPage: {}
  },
  auths: {
    loginErrors: ''
  },
  claims: {
    createdOrAttendedClaims: [],
    claimLoader: false,
    assignedClaims: [],
    allClaims: [],
    detailedClaims: [],
    claimsStats: {},
    csvData: [],
    csvAttributes: [],
    categories: [],
    claimDetail: {},
    childClaims: {},
    redeemableClaims: [],
    slotStatus: {}
  },
  claimTypes: {
    claimTypes: [],
    claimType: [],
    updatedClaimType: [],
    claimTypeLoader: false,
    displayClaimTypeForm: false,
    claimTypesDetail: []
  },
  integrations: {
    appIntegrations: []
  },
  userSettings: {
    tab: 'password',
    preferences: {}
  },
  claimsBlacklist: {
    claimsBlacklist: []
  },
  haDashboard: {
    probationUsers: {
      users: [],
      probation_stats: []
    },
    reviewUsers: {
      reviews: [],
      review_stats: []
    },
    probationUserDetail: {},
    reviewUserDetail: {},
    loader: false,
    selectedDelegatedUser: {},
    csvAttributes: [],
    csvData: {},
    announcements: [],
    reviewsTimeline: {}
  },
  events: {
    userEvents: {
      events: [],
      hierarchy_count: 0
    }
  },
  workflow: {
    workflowsCategories: [],
    workflowsCategory: {},
    workflow: {},
    task: {},
    userTasks: [],
    userTask: {
      subtasks: []
    },
    workflowOptions: [],
    tasksOptions: [],
    sequentialTasksJson: [],
    sequentialTaskLoader: false
  },
  subscriptions: {
    liveCounter: [],
    redeemedEntryData: [],
    activeSubscription: [],
    redeemableTimeSlots: [],
    buffer: 0
  },
  educationDetail: {
    educationMajor: [],
    educationDegree: []
  },
  countries: {
    countriesList: [],
    regions: []
  },
  nationalityIdentifier: {
    nationalities: [],
    selectNationalities: []
  },
  documentSettings: {
    settings: []
  },
  ams: {
    attendance: {}
  },
  thirdPartyVendors: {
    thirdPartyVendors: []
  }
}

export default initialState
