import { Link } from 'react-router-dom'
import { Box, Typography } from 'maple-storybook'
import { globe, Icon } from 'maple-storybook/assets'

import StyledFooter from 'variables/styles/footerStyle'

function Footer({ hasFaqPermission }) {
  return (
    <StyledFooter className="footer">
      <div className="container-style">
        <Box component="div" className="d-flex align-items-center">
          <Link
            to="https://maplehr.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="maple-web-link d-flex align-items-center">
            <Icon
              transform={''}
              paths={globe}
              strokeColor="gray-500"
              fillColor="white"
              size={20}
              className="globe-icon"
            />
            <Typography type="normal" variant="caption">
              Maplehr.io
            </Typography>
          </Link>
          {hasFaqPermission && (
            <>
              &nbsp; |&nbsp;
              <Typography type="normal" variant="caption">
                <a target="_blank" rel="noopener noreferrer" href="/faqs" className="maple-web-link">
                  FAQ's
                </a>
              </Typography>
            </>
          )}
        </Box>
        <Typography
          color="gray-500"
          type="normal"
          variant="caption"
          align="center"
          floatRight
          className="d-flex align-items-center company-rights">
          &nbsp; &copy; {1900 + new Date().getYear()}. All rights reserved.
        </Typography>
      </div>
    </StyledFooter>
  )
}
export default Footer
