//User Action Types
export const RESET_STATE_TO_INITIAL = 'RESET_STATE_TO_INITIAL'

export const SET_USERS_TAB = 'SET_USERS_TAB'
export const SET_USER_SETTINGS_TAB = 'SET_USER_SETTINGS_TAB'
export const FETCH_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const FETCH_SEARCH_USERS_SUCCESS = 'FETCH_SEARCH_USERS_SUCCESS'
export const FETCH_SEARCH_USERS_FAILURE = 'FETCH_SEARCH_USERS_FAILURE'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_DEPARTMENT_USERS_SUCCESS = 'FETCH_DEPARTMENT_USERS_SUCCESS'
export const FETCH_DEPARTMENT_USERS_FAILURE = 'FETCH_DEPARTMENT_USERS_FAILURE'

export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
export const UPDATE_RAW_USER_SUCCESS = 'UPDATE_RAW_USER_SUCCESS'
export const FETCH_OFFBOARDING_USERS_SUCCESS = 'FETCH_OFFBOARDING_USERS_SUCCESS'
export const UPDATE_OFFBOARDING_USER_TASKS_SUCCESS = 'UPDATE_OFFBOARDING_USER_TASKS_SUCCESS'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const CLEAR_CREATE_USER_FORM_ERRORS = 'CLEAR_CREATE_USER_FORM_ERRORS'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'
export const UPDATE_OFFBOARDING_USER_SETTINGS_SUCCESS = 'UPDATE_OFFBOARDING_USER_SETTINGS_SUCCESS'
export const REPLACE_DISCARD_TICKET_APPROVAL_SUCCESS = 'REPLACE_DISCARD_TICKET_APPROVAL_SUCCESS'
export const FETCH_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE'
export const FETCH_TEAM_ANALYTICS_SUCCESS = 'GET_TEAM_ANALYTICS_SUCCESS'
export const FETCH_TEAM_ANALYTICS_FAILURE = 'GET_TEAM_ANALYTICS_FAILURE'
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS'
export const SEND_RESET_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE'
export const CLEAR_RESET_PASSWORD_STATUS = 'CLEAR_RESET_PASSWORD_STATUS'
export const RESEND_WELCOME_EMAIL_SUCCESS = 'RESEND_WELCOME_EMAIL_SUCCESS'
export const RESEND_WELCOME_EMAIL_FAILURE = 'RESEND_WELCOME_EMAIL_FAILURE'
export const FETCH_ORGANO_CHART_SUCCESS = 'FETCH_ORGANO_CHART_SUCCESS'
export const FETCH_ORGANO_CHART_FAILURE = 'FETCH_ORGANO_CHART_FAILURE'
export const FETCH_WORKFLOW_ORGANO_CHART_SUCCESS = 'FETCH_WORKFLOW_ORGANO_CHART_SUCCESS'
export const FETCH_WORKFLOW_ORGANO_CHART_FAILURE = 'FETCH_WORKFLOW_ORGANO_CHART_FAILURE'
export const SHOW_WORKFLOW_ORGANO_CHART_LOADER = 'SHOW_WORKFLOW_ORGANO_CHART_LOADER'
export const HIDE_WORKFLOW_ORGANO_CHART_LOADER = 'HIDE_WORKFLOW_ORGANO_CHART_LOADER'
export const FIELDS_FILLED_SUCCESS = 'FIELDS_FILLED_SUCCESS'
export const FIELDS_FILLED_FAILURE = 'FIELDS_FILLED_FAILURE'
export const RESET_PROFILE_UPDATED = 'RESET_PROFILE_UPDATED'
export const FETCH_ASSIGN_TO_USER_SUCCESS = 'FETCH_ASSIGN_TO_USER_SUCCESS'
export const FETCH_ASSIGN_TO_USER_FAILURE = 'FETCH_ASSIGN_TO_USER_FAILURE'
export const FETCH_SELECT_USERS_SUCCESS = 'FETCH_SELECT_USERS_SUCCESS'
export const FETCH_SELECT_USERS_FAILURE = 'FETCH_SELECT_USERS_FAILURE'
export const FETCH_BLOCKED_USERS_SUCCESS = 'FETCH_BLOCKED_USERS_SUCCESS'
export const FETCH_BLOCKED_USERS_FAILURE = 'FETCH_BLOCKED_USERS_FAILURE'
export const FETCH_USER_SUBORDINATES_SUCCESS = 'FETCH_USER_SUBORDINATES_SUCCESS'
export const FETCH_USER_SUBORDINATES_FAILURE = 'FETCH_USER_SUBORDINATES_FAILURE'
export const RESET_USER_SUBORDINATES = 'RESET_USER_SUBORDINATES'
export const HANDLE_USER_SEARCH_CHANGE = 'HANDLE_USER_SEARCH_CHANGE'
export const HANDLE_DATE_RANGE_SELECTED_DATES = 'HANDLE_DATE_RANGE_SELECTED_DATES'
export const FETCH_USER_CLEARANCE_DATA_SUCCESS = 'FETCH_USER_CLEARANCE_DATA_SUCCESS'
export const FETCH_USER_CLEARANCE_DATA_FAILURE = 'FETCH_USER_CLEARANCE_DATA_FAILURE'
export const RESET_USER_CLEARANCE_DATA = 'RESET_USER_CLEARANCE_DATA'
export const UPDATE_DEPARTMENT_CLEARANCE_DATA_SUCCESS = 'UPDATE_DEPARTMENT_CLEARANCE_DATA_SUCCESS'
export const UPDATE_INVENTORY_CLEARANCE_DATA_SUCCESS = 'UPDATE_INVENTORY_CLEARANCE_DATA_SUCCESS'
export const UPDATE_DNARS_CLEARANCE_DATA_SUCCESS = 'UPDATE_DNARS_CLEARANCE_DATA_SUCCESS'
export const UPDATE_TASKS_CLEARANCE_DATA_SUCCESS = 'UPDATE_TASKS_CLEARANCE_DATA_SUCCESS'
export const UPDATE_LEAVES_CLEARANCE_DATA_SUCCESS = 'UPDATE_LEAVES_CLEARANCE_DATA_SUCCESS'
export const UPDATE_CLAIMS_CLEARANCE_DATA_SUCCESS = 'UPDATE_CLAIMS_CLEARANCE_DATA_SUCCESS'
export const UPDATE_TICKETS_CLEARANCE_DATA_SUCCESS = 'UPDATE_TICKETS_CLEARANCE_DATA_SUCCESS'
export const UPDATE_TRANSFERRED_DNARS_CLEARANCE_DATA_SUCCESS = 'UPDATE_TRANSFERRED_DNARS_CLEARANCE_DATA_SUCCESS'
export const FETCH_USERS_CSV_ATTRIBUTES_SUCCESS = 'FETCH_USERS_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_USERS_CSV_ATTRIBUTES_FAILURE = 'FETCH_USERS_CSV_ATTRIBUTES_FAILURE'
export const CREATE_RESELL_ITEM_SUCCESS = 'CREATE_RESELL_ITEM_SUCCESS'
export const CREATE_REPAIR_ITEM_SUCCESS = 'CREATE_REPAIR_ITEM_SUCCESS'
export const FETCH_CLAIM_CSV_ATTRIBUTES_SUCCESS = 'FETCH_CLAIM_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_CLAIM_CSV_ATTRIBUTES_FAILURE = 'FETCH_CLAIM_CSV_ATTRIBUTES_FAILURE'
export const FETCH_TICKET_CSV_ATTRIBUTES_SUCCESS = 'FETCH_TICKET_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_TICKET_CSV_ATTRIBUTES_FAILURE = 'FETCH_TICKET_CSV_ATTRIBUTES_FAILURE'
export const FETCH_INVOICE_CSV_ATTRIBUTES_SUCCESS = 'FETCH_INVOICE_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_INVOICE_CSV_ATTRIBUTES_FAILURE = 'FETCH_INVOICE_CSV_ATTRIBUTES_FAILURE'
export const FETCH_PROBATION_CSV_ATTRIBUTES_SUCCESS = 'FETCH_PROBATION_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_PROBATION_CSV_ATTRIBUTES_FAILURE = 'FETCH_PROBATION_CSV_ATTRIBUTES_FAILURE'
export const FETCH_REVIEW_CSV_ATTRIBUTES_SUCCESS = 'FETCH_REVIEW_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_REVIEW_CSV_ATTRIBUTES_FAILURE = 'FETCH_REVIEW_CSV_ATTRIBUTES_FAILURE'
export const FETCH_PROJECT_CSV_ATTRIBUTES_SUCCESS = 'FETCH_PROJECT_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_PROJECT_CSV_ATTRIBUTES_FAILURE = 'FETCH_PROJECT_CSV_ATTRIBUTES_FAILURE'
export const UPDATE_PROJECT_MEMBERS_SUCCESS = 'UPDATE_PROJECT_MEMBERS_SUCCESS'
export const FETCH_PROJECT_ELIGIBLE_MEMBERS_SUCCESS = 'FETCH_PROJECT_ELIGIBLE_MEMBERS_SUCCESS'
export const UPDATE_PROJECT_ELIGIBLE_MEMBERS_SUCCESS = 'UPDATE_PROJECT_ELIGIBLE_MEMBERS_SUCCESS'
export const FETCH_PROBATION_CSV_DATA_SUCCESS = 'FETCH_PROBATION_CSV_DATA_SUCCESS'
export const FETCH_PROBATION_CSV_DATA_FAILURE = 'FETCH_PROBATION_CSV_DATA_FAILURE'
export const FETCH_REVIEW_CSV_DATA_SUCCESS = 'FETCH_REVIEW_CSV_DATA_SUCCESS'
export const FETCH_REVIEW_CSV_DATA_FAILURE = 'FETCH_REVIEW_CSV_DATA_FAILURE'
export const FETCH_PROJECT_CSV_DATA_SUCCESS = 'FETCH_PROJECT_CSV_DATA_SUCCESS'
export const FETCH_PROJECT_CSV_DATA_FAILURE = 'FETCH_PROJECT_CSV_DATA_FAILURE'
export const SHOW_USER_LOADER = 'SHOW_USER_LOADER'
export const HIDE_USER_LOADER = 'HIDE_USER_LOADER'
export const SHOW_ORGANO_CHART_LOADER = 'SHOW_ORGANO_CHART_LOADER'
export const HIDE_ORGANO_CHART_LOADER = 'HIDE_ORGANO_CHART_LOADER'
export const FETCH_UNIVERSITIES_SUCCESS = 'FETCH_UNIVERSITIES_SUCCESS'
export const FETCH_UNIVERSITIES_FAILURE = 'FETCH_UNIVERSITIES_FAILURE'
export const CREATE_UNIVERSITY_SUCCESS = 'CREATE_UNIVERSITY_SUCCESS'
export const FETCH_HEADER_DATA_SUCCESS = 'FETCH_HEADER_DATA_SUCCESS'
export const FETCH_HEADER_DATA_FAILURE = 'FETCH_HEADER_DATA_FAILURE'
export const FETCH_DAY_FEEDBACK_SUCCESS = 'FETCH_DAY_FEEDBACK_SUCCESS'
export const FETCH_DAY_FEEDBACK_FAILURE = 'FETCH_DAY_FEEDBACK_FAILURE'
export const CREATE_DAY_FEEDBACK_SUCCESS = 'CREATE_DAY_FEEDBACK_SUCCESS'
export const CREATE_DAY_FEEDBACK_FAILURE = 'CREATE_DAY_FEEDBACK_FAILURE'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'
export const CREATE_LOCATIONS_SUCCESS = 'CREATE_LOCATIONS_SUCCESS'
export const CREATE_LOCATIONS_FAILURE = 'CREATE_LOCATIONS_FAILURE'
export const UPDATE_LOCATIONS_SUCCESS = 'UPDATE_LOCATIONS_SUCCESS'
export const UPDATE_LOCATIONS_FAILURE = 'UPDATE_LOCATIONS_FAILURE'
export const DELETE_LOCATIONS_SUCCESS = 'DELETE_LOCATIONS_SUCCESS'
export const DELETE_LOCATIONS_FAILURE = 'DELETE_LOCATIONS_FAILURE'
export const FETCH_EMPLOYMENT_COMPANIES_SUCCESS = 'FETCH_EMPLOYMENT_COMPANIES_SUCCESS'
export const FETCH_EMPLOYMENT_COMPANIES_FAILURE = 'FETCH_EMPLOYMENT_COMPANIES_FAILURE'
export const CREATE_EMPLOYMENT_COMPANIES_SUCCESS = 'CREATE_EMPLOYMENT_COMPANIES_SUCCESS'
export const CREATE_EMPLOYMENT_COMPANIES_FAILURE = 'CREATE_EMPLOYMENT_COMPANIES_SUCCESS'
export const FETCH_EMPLOYMENT_POSITIONS_SUCCESS = 'FETCH_EMPLOYMENT_POSITIONS_SUCCESS'
export const FETCH_EMPLOYMENT_POSITIONS_FAILURE = 'FETCH_EMPLOYMENT_POSITIONS_FAILURE'
export const FETCH_EMPLOYMENT_TYPES_SUCCESS = 'FETCH_EMPLOYMENT_TYPES_SUCCESS'
export const FETCH_EMPLOYMENT_TYPES_FAILURE = 'FETCH_EMPLOYMENT_TYPES_FAILURE'
export const CREATE_EMPLOYMENT_POSITIONS_SUCCESS = 'CREATE_EMPLOYMENT_POSITIONS_SUCCESS'
export const CREATE_EMPLOYMENT_POSITIONS_FAILURE = 'CREATE_EMPLOYMENT_POSITIONS_FAILURE'
export const VERIFICATION_STATUS_SUCCESS = 'VERIFICATION_STATUS_SUCCESS'
export const FETCH_VERIFICATION_USERS_SUCCESS = 'FETCH_VERIFICATION_USERS_SUCCESS'
export const USER_BULK_VERIFICATION_USERS_SUCCESS = 'USER_BULK_VERIFICATION_USERS_SUCCESS'
export const FETCH_ONBOARDING_USERS_SUCCESS = 'FETCH_ONBOARDING_USERS_SUCCESS'
export const FETCH_CREATION_LOGS_SUCCESS = 'FETCH_CREATION_LOGS_SUCCESS'
export const FETCH_CREATION_LOGS_FAILURE = 'FETCH_CREATION_LOGS_FAILURE'
export const EDIT_ONBOARDING_USERS_SUCCESS = 'EDIT_ONBOARDING_USERS_SUCCESS'
export const UPDATE_WORKFLOW_TASK_ASSIGNEES_SUCCESS = 'UPDATE_WORKFLOW_TASK_ASSIGNEES_SUCCESS'

export const FETCH_BULK_USERS_CSV_ATTRIBUTES_SUCCESS = 'FETCH_BULK_USERS_CSV_ATTRIBUTES_SUCCESS'
export const FETCH_BULK_USERS_CSV_ATTRIBUTES_FAILURE = 'FETCH_BULK_USERS_CSV_ATTRIBUTES_FAILURE'
export const UPLOAD_BULK_USER_CSV_SUCCESS = 'UPLOAD_BULK_USER_CSV_SUCCESS'
export const UPLOAD_BULK_USER_CSV_FAILURE = 'UPLOAD_BULK_USER_CSV_FAILURE'
export const UPDATE_BULK_USER_CSV_ERRORS = 'UPDATE_BULK_USER_CSV_ERRORS'

export const ENCASH_OFFBOARDING_USER_LEAVES_SUCCESS = 'ENCASH_OFFBOARDING_USER_LEAVES_SUCCESS'

export const FETCH_PROBATION_USERS_SUCCESS = 'FETCH_PROBATION_USERS_SUCCESS'
export const FETCH_PROBATION_USER_TASK_UPDATION_SUCCESS = 'FETCH_PROBATION_USER_TASK_UPDATION_SUCCESS'
export const FETCH_PROBATION_USERS_FAILURE = 'FETCH_PROBATION_USERS_FAILURE'
export const FETCH_PROBATION_USER_SUCCESS = 'FETCH_PROBATION_USER_SUCCESS'
export const FETCH_PROBATION_USER_FAILURE = 'FETCH_PROBATION_USER_FAILURE'
export const CREATE_USER_PROBATION_STATUS_SUCCESS = 'CREATE_USER_PROBATION_STATUS_SUCCESS'
export const CREATE_USER_PROBATION_STATUS_FAILURE = 'CREATE_USER_PROBATION_STATUS_FAILURE'
export const UPDATE_USER_PROBATION_STATUS_SUCCESS = 'UPDATE_USER_PROBATION_STATUS_SUCCESS'
export const UPDATE_USER_PROBATION_STATUS_FAILURE = 'UPDATE_USER_PROBATION_STATUS_FAILURE'
export const SHOW_PROBATION_USER_LOADER = 'SHOW_PROBATION_USER_LOADER'
export const FETCH_REVIEW_USERS_SUCCESS = 'FETCH_REVIEW_USERS_SUCCESS'
export const FETCH_REVIEW_USER_TASK_UPDATION_SUCCESS = 'FETCH_REVIEW_USER_TASK_UPDATION_SUCCESS'
export const FETCH_REVIEW_USERS_FAILURE = 'FETCH_REVIEW_USERS_FAILURE'
export const UPDATE_REVIEW_STATUS_SUCCESS = 'UPDATE_REVIEW_STATUS_SUCCESS'
export const UPDATE_REVIEW_STATUS_FAILURE = 'UPDATE_REVIEW_STATUS_FAILURE'
export const CREATE_REVIEW_FEEDBACK_SUCCESS = 'CREATE_REVIEW_FEEDBACK_SUCCESS'
export const CREATE_REVIEW_FEEDBACK_FAILURE = 'CREATE_REVIEW_FEEDBACK_FAILURE'
export const FETCH_REVIEW_USER_SUCCESS = 'FETCH_REVIEW_USER_SUCCESS'
export const FETCH_REVIEW_USER_FAILURE = 'FETCH_REVIEW_USER_FAILURE'
export const UPDATE_REVIEW_FEEDBACK_SUCCESS = 'UPDATE_REVIEW_FEEDBACK_SUCCESS'
export const UPDATE_REVIEW_FEEDBACK_FAILURE = 'UPDATE_REVIEW_FEEDBACK_FAILURE'
export const FETCH_REVIEW_EVALUATION_SUCCESS = 'FETCH_REVIEW_EVALUATION_SUCCESS'
export const FETCH_REVIEW_EVALUATION_FAILURE = 'FETCH_REVIEW_EVALUATION_FAILURE'

export const FETCH_DEPARTMENTAL_USERS_SUCCESS = 'FETCH_DEPARTMENTAL_USERS_SUCCESS'
export const FETCH_DEPARTMENTAL_USERS_FAILURE = 'FETCH_DEPARTMENTAL_USERS_FAILURE'
export const FETCH_TICKET_ASSIGNABLE_USERS_SUCCESS = 'FETCH_TICKET_ASSIGNABLE_USERS_SUCCESS'
export const FETCH_TICKET_ASSIGNABLE_USERS_FAILURE = 'FETCH_TICKET_ASSIGNABLE_USERS_FAILURE'

//Modal Action Types
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

//Modal Types
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL'
export const FORM_MODAL = 'FORM_MODAL'
export const CONTENT_MODAL = 'CONTENT_MODAL'
export const INFO_MODAL = 'INFO_MODAL'
export const QR_MODAL = 'QR_MODAL'
export const BASE_MODAL = 'BASE_MODAL'

//Ticket Action Types
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS'
export const FETCH_TICKET_FAILURE = 'FETCH_TICKET_FAILURE'
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS'
export const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE'
export const FETCH_ASSIGNED_TICKETS_SUCCESS = 'FETCH_ASSIGNED_TICKETS_SUCCESS'
export const FETCH_ASSIGNED_TICKETS_FAILURE = 'FETCH_ASSIGNED_TICKETS_FAILURE'
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS'
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE'
export const UPDATE_SHOW_TICKET_STATUS_SUCCESS = 'UPDATE_SHOW_TICKET_STATUS_SUCCESS'
export const UPDATE_TICKET_STATUS_SUCCESS = 'UPDATE_TICKET_STATUS_SUCCESS'
export const UPDATE_TICKET_STATUS_FAILURE = 'UPDATE_TICKET_STATUS_FAILURE'
export const FETCH_TICKET_OPTION_SUCCESS = 'FETCH_TICKET_OPTION_SUCCESS'
export const FETCH_TICKET_OPTION_FAILURE = 'FETCH_TICKET_OPTION_FAILURE'
export const FETCH_TICKET_COMMENTS_SUCCESS = 'FETCH_TICKET_COMMENTS_SUCCESS'
export const FETCH_TICKET_COMMENTS_FAILURE = 'FETCH_TICKET_COMMENTS_FAILURE'
export const CREATE_TICKET_COMMENT_SUCCESS = 'CREATE_TICKET_COMMENT_SUCCESS'
export const CREATE_TICKET_COMMENT_FAILURE = 'CREATE_TICKET_COMMENT_FAILURE'
export const FETCH_SEARCHED_TICKETS_SUCCESS = 'FETCH_ALL_TICKETS_SUCCESS'
export const FETCH_SEARCHED_TICKETS_FAILURE = 'FETCH_ALL_TICKETS_FAILURE'
export const SET_FILTERED_TICKETS = 'SET_FILTERED_TICKETS'
export const CHANGE_TICKET_PARTICIPANTS_SUCCESS = 'CHANGE_TICKET_PARTICIPANTS_SUCCESS'
export const CHANGE_TICKET_PARTICIPANTS_FAILURE = 'CHANGE_TICKET_PARTICIPANTS_FAILURE'
export const CLEAR_TICKET = 'CLEAR_TICKET'
export const HANDLE_TICKET_SEARCH_CHANGE = 'HANDLE_TICKET_SEARCH_CHANGE'
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS'
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE'
export const FETCH_TICKET_STATS_SUCCESS = 'FETCH_TICKET_STATS_SUCCESS'
export const FETCH_TICKET_STATS_FAILURE = 'FETCH_TICKET_STATS_FAILURE'
export const FETCH_TICKETS_CSV_DATA_SUCCESS = 'FETCH_TICKETS_CSV_DATA_SUCCESS'
export const FETCH_TICKETS_CSV_DATA_FAILURE = 'FETCH_TICKETS_CSV_DATA_FAILURE'
export const FETCH_ASSIGNABLE_USERS_SUCCESS = 'FETCH_ASSIGNABLE_USERS_SUCCESS'
export const FETCH_ASSIGNABLE_USERS_FAILURE = 'FETCH_ASSIGNABLE_USERS_FAILURE'

export const FETCH_TICKET_CATEGORIES_SUCCESS = 'FETCH_TICKET_CATEGORIES_SUCCESS'
export const FETCH_TICKET_CATEGORIES_FAILURE = 'FETCH_TICKET_CATEGORIES_FAILURE'
export const FETCH_CURRENT_COMPANY_SUCCESS = 'FETCH_CURRENT_COMPANY_SUCCESS'
export const FETCH_CURRENT_COMPANY_FAILURE = 'FETCH_CURRENT_COMPANY_FAILURE'
export const UPDATE_COMPANY_LOGO_SUCCESS = 'UPDATE_COMPANY_LOGO_SUCCESS'
export const UPDATE_COMPANY_LOGO_FAILURE = 'UPDATE_COMPANY_LOGO_FAILURE'
export const CREATE_TICKET_CATEGORY_SUCCESS = 'CREATE_TICKET_CATEGORY_SUCCESS'
export const CREATE_TICKET_CATEGORY_FAILURE = 'CREATE_TICKET_CATEGORY_FAILURE'
export const CLEAR_TICKET_FETCHED = 'CLEAR_TICKET_FETCHED'
export const SHOW_TICKET_LOADER = 'SHOW_TICKET_LOADER'
export const HIDE_TICKET_LOADER = 'HIDE_TICKET_LOADER'
export const FETCH_TICKET_ACTIONS_SUCCESS = 'FETCH_TICKET_ACTIONS_SUCCESS'
export const CREATE_TICKET_ACTIONS_SUCCESS = 'CREATE_TICKET_ACTIONS_SUCCESS'
export const DELETE_TICKET_ACTIONS_SUCCESS = 'DELETE_TICKET_ACTIONS_SUCCESS'
export const UPDATE_TICKET_REPORT_DATA_SUCCESS = 'UPDATE_TICKET_REPORT_DATA_SUCCESS'
export const GET_TICKET_REPORTS_LAST_UPDATE_SUCCESS = 'GET_TICKET_REPORTS_LAST_UPDATE_SUCCESS'
export const CREATE_SCHEDULE_EMAIL_SUCCESS = 'CREATE_SCHEDULE_EMAIL_SUCCESS'
export const DELETE_SCHEDULE_EMAIL_SUCCESS = 'DELETE_SCHEDULE_EMAIL_SUCCESS'
export const FETCH_ALL_SCHEDULES_EMAILS_SUCCESS = 'FETCH_ALL_SCHEDULES_EMAILS_SUCCESS'

// EMPLYEE GROUPS
export const CREATE_BUSINESS_UNITS_SUCCESS = 'CREATE_BUSINESS_UNITS_SUCCESS'
export const FETCH_BUSINESS_UNITS_SUCCESS = 'FETCH_BUSINESS_UNITS_SUCCESS'

export const FETCH_TICKET_CATEGORY_SUCCESS = 'FETCH_TICKET_CATEGORY_SUCCESS'
export const FETCH_TICKET_CATEGORY_FAILURE = 'FETCH_TICKET_CATEGORY_FAILURE'
export const UPDATE_TICKET_CATEGORY_SUCCESS = 'UPDATE_TICKET_CATEGORY_SUCCESS'
export const UPDATE_TICKET_CATEGORY_FAILURE = 'UPDATE_TICKET_CATEGORY_FAILURE'
export const DELETE_TICKET_CATEGORY_SUCCESS = 'DELETE_TICKET_CATEGORY_SUCCESS'
export const DELETE_TICKET_CATEGORY_FAILURE = 'DELETE_TICKET_CATEGORY_FAILURE'
export const FETCH_TICKET_CATEGORY_CHANGE_LOGS_SUCCESS = 'FETCH_TICKET_CATEGORY_CHANGE_LOGS_SUCCESS'
export const FETCH_TICKET_CATEGORY_CHANGE_LOGS_FAILURE = 'FETCH_TICKET_CATEGORY_CHANGE_LOGS_FAILURE'

//Leave Action Types
export const SET_LEAVES_TAB = 'SET_LEAVES_TAB'
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS'
export const CREATE_LEAVE_FAILURE = 'CREATE_LEAVE_FAILURE'
export const FETCH_LEAVES_SUCCESS = 'FETCH_LEAVES_SUCCESS'
export const FETCH_LEAVES_FAILURE = 'FETCH_LEAVES_FAILURE'
export const UPDATE_LEAVE_SUCCESS = 'UPDATE_LEAVE_SUCCESS'
export const UPDATE_LEAVE_FAILURE = 'UPDATE_LEAVE_FAILURE'
export const FETCH_LEAVE_APPROVALS_SUCCESS = 'FETCH_LEAVE_APPROVALS_SUCCESS'
export const FETCH_LEAVE_APPROVALS_FAILURE = 'FETCH_LEAVE_APPROVALS_FAILURE'
export const CREATE_LEAVE_STATUS_SUCCESS = 'CREATE_LEAVE_STATUS_SUCCESS'
export const CREATE_LEAVE_STATUS_FAILURE = 'CREATE_LEAVE_STATUS_FAILURE'
export const CREATE_LEAVE_STATUS_FOR_APPLICANT_SUCCESS = 'CREATE_LEAVE_STATUS_FOR_APPLICANT_SUCCESS'
export const CREATE_LEAVE_STATUS_FOR_APPLICANT_FAILURE = 'CREATE_LEAVE_STATUS_FOR_APPLICANT_FAILURE'
export const FETCH_USER_LEAVES_HISTORY_SUCCESS = 'FETCH_USER_LEAVES_HISTORY_SUCCESS'
export const FETCH_USER_LEAVES_HISTORY_FAILURE = 'FETCH_USER_LEAVES_HISTORY_FAILURE'
export const FETCH_USER_LEAVE_HISTORY_SUCCESS = 'FETCH_USER_LEAVE_HISTORY_SUCCESS'
export const FETCH_USER_LEAVE_HISTORY_FAILURE = 'FETCH_USER_LEAVE_HISTORY_FAILURE'
export const CLEAR_USER_LEAVES_HISTORY = 'CLEAR_USER_LEAVES_HISTORY'
export const FETCH_LEAVE_LIFECYCLE_SUCCESS = 'FETCH_LEAVE_LIFECYCLE_SUCCESS'
export const FETCH_LEAVE_LIFECYCLE_FAILURE = 'FETCH_LEAVE_LIFECYCLE_FAILURE'
export const FETCH_ALL_LEAVES_SUMMARY_SUCCESS = 'FETCH_ALL_LEAVES_SUCCESS'
export const FETCH_ALL_LEAVES_SUMMARY_FAILURE = 'FETCH_ALL_LEAVES_FAILURE'
export const FETCH_ALL_USERS_LEAVES_HISTORY_SUCCESS = 'FETCH_ALL_USERS_LEAVES_HISTORY_SUCCESS'
export const FETCH_ALL_USERS_LEAVES_HISTORY_FAILURE = 'FETCH_ALL_USERS_LEAVES_HISTORY_FAILURE'
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS'
export const DELETE_LEAVE_FAILURE = 'DELETE_LEAVE_FAILURE'
export const FETCH_MANUALLY_ADJUSTED_LEAVES_SUCCESS = 'FETCH_MANUALLY_ADJUSTED_LEAVES_SUCCESS'
export const FETCH_MANUALLY_ADJUSTED_LEAVES_FAILURE = 'FETCH_MANUALLY_ADJUSTED_LEAVES_FAILURE'
export const CREATE_HR_LEAVE_STATUS_SUCCESS = 'CREATE_HR_LEAVE_STATUS_SUCCESS'
export const CREATE_HR_LEAVE_STATUS_FAILURE = 'CREATE_HR_LEAVE_STATUS_FAILURE'
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS'
export const CREATE_HOLIDAY_FAILURE = 'CREATE_HOLIDAY_FAILURE'
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS'
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE'
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS'
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE'
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS'
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE'
export const FETCH_UPCOMING_HOLIDAYS_SUCCESS = 'FETCH_UPCOMING_HOLIDAYS_SUCCESS'
export const FETCH_UPCOMING_HOLIDAYS_FAILURE = 'FETCH_UPCOMING_HOLIDAYS_FAILURE'
export const FETCH_RANGE_LEAVES_SUCCESS = 'FETCH_RANGE_LEAVES_SUCCESS'
export const FETCH_RANGE_LEAVES_FAILURE = 'FETCH_RANGE_LEAVES_FAILURE'
export const CLEAR_RANGE_LEAVES = 'CLEAR_RANGE_LEAVES'
export const SHOW_LEAVE_LOADER = 'SHOW_LEAVE_LOADER'
export const HIDE_LEAVE_LOADER = 'HIDE_LEAVE_LOADER'
export const CREATE_MANUAL_LEAVE_SUCCESS = 'CREATE_MANUAL_LEAVE_SUCCESS'
export const CREATE_MANUAL_LEAVE_FAILURE = 'CREATE_MANUAL_LEAVE_FAILURE'
export const SET_LEAVE_APPROVALS_PAGE_SIZE = 'SET_LEAVE_APPROVALS_PAGE_SIZE'
export const FETCH_LEAVE_TYPES_SUCCESS = 'FETCH_LEAVE_TYPES_SUCCESS'
export const FETCH_LEAVE_TYPES_FAILURE = 'FETCH_LEAVE_TYPES_FAILURE'
export const FETCH_LEAVE_TYPE_SUCCESS = 'FETCH_LEAVE_TYPE_SUCCESS'
export const FETCH_LEAVE_TYPE_FAILURE = 'FETCH_LEAVE_TYPE_FAILURE'
export const CREATE_LEAVE_TYPE_SUCCESS = 'CREATE_LEAVE_TYPE_SUCCESS'
export const CREATE_LEAVE_TYPE_FAILURE = 'CREATE_LEAVE_TYPE_FAILURE'
export const UPDATE_LEAVE_TYPE_SUCCESS = 'UPDATE_LEAVE_TYPE_SUCCESS'
export const UPDATE_LEAVE_TYPE_FAILURE = 'UPDATE_LEAVE_TYPE_FAILURE'
export const DELETE_LEAVE_TYPE_SUCCESS = 'DELETE_LEAVE_TYPE_SUCCESS'
export const DELETE_LEAVE_TYPE_FAILURE = 'DELETE_LEAVE_TYPE_FAILURE'
export const FETCH_SANDWICH_POLICY_SUCCESS = 'FETCH_SANDWICH_POLICY_SUCCESS'
export const FETCH_SANDWICH_POLICY_FAILURE = 'FETCH_SANDWICH_POLICY_FAILURE'
export const CREATE_SANDWICH_POLICY_SUCCESS = 'CREATE_SANDWICH_POLICY_SUCCESS'
export const CREATE_SANDWICH_POLICY_FAILURE = 'CREATE_SANDWICH_POLICY_FAILURE'
export const UPDATE_SANDWICH_POLICY_SUCCESS = 'UPDATE_SANDWICH_POLICY_SUCCESS'
export const UPDATE_SANDWICH_POLICY_FAILURE = 'UPDATE_SANDWICH_POLICY_FAILURE'
export const DELETE_SANDWICH_POLICY_SUCCESS = 'DELETE_SANDWICH_POLICY_SUCCESS'
export const DELETE_SANDWICH_POLICY_FAILURE = 'DELETE_SANDWICH_POLICY_FAILURE'
export const APPLY_LEAVE_ENCASHMENT_SUCCESS = 'APPLY_LEAVE_ENCASHMENT_SUCCESS'
export const APPLY_LEAVE_ENCASHMENT_FAILURE = 'APPLY_LEAVE_ENCASHMENT_FAILURE'
export const APPLY_LEAVE_ANNUAL_ROLLOVER_SUCCESS = 'APPLY_LEAVE_ANNUAL_ROLLOVER_SUCCESS'
export const APPLY_LEAVE_ANNUAL_ROLLOVER_FAILURE = 'APPLY_LEAVE_ANNUAL_ROLLOVER_FAILURE'
export const APPLY_LEAVE_ENCASHMENT_ROLLOVER_SUCCESS = 'APPLY_LEAVE_ENCASHMENT_ROLLOVER_SUCCESS'
export const APPLY_LEAVE_ENCASHMENT_ROLLOVER_FAILURE = 'APPLY_LEAVE_ENCASHMENT_ROLLOVER_FAILURE'

// LeavePolicy Action Types
export const CREATE_LEAVE_POLICY_SUCCESS = 'CREATE_LEAVE_POLICY_SUCCESS'
export const CREATE_LEAVE_POLICY_FAILURE = 'CREATE_LEAVE_POLICY_FAILURE'
export const FETCH_LEAVE_POLICIES_SUCCESS = 'FETCH_LEAVE_POLICIES_SUCCESS'
export const FETCH_LEAVE_POLICIES_FAILURE = 'FETCH_LEAVE_POLICIES_FAILURE'
export const UPDATE_LEAVE_POLICY_SUCCESS = 'UPDATE_LEAVE_POLICY_SUCCESS'
export const UPDATE_LEAVE_POLICY_FAILURE = 'UPDATE_LEAVE_POLICY_FAILURE'
export const FETCH_LEAVE_POLICIES_QUOTA_SUCCESS = 'FETCH_LEAVE_POLICIES_QUOTA_SUCCESS'
export const FETCH_LEAVE_POLICIES_QUOTA_FAILURE = 'FETCH_LEAVE_POLICIES_QUOTA_FAILURE'
export const CLEAR_LEAVE_POLICIES_QUOTA = 'CLEAR_LEAVE_POLICIES_QUOTA'
export const FETCH_LEAVE_POLICY_BY_YEAR_SUCCESS = 'FETCH_LEAVE_POLICY_BY_YEAR_SUCCESS'
export const FETCH_LEAVE_POLICY_BY_YEAR_FAILURE = 'FETCH_LEAVE_POLICY_BY_YEAR_FAILURE'
export const FETCH_DYNAMIC_LEAVE_APPROVALS_SUCCESS = 'FETCH_DYNAMIC_LEAVE_APPROVALS_SUCCESS'
export const FETCH_DYNAMIC_LEAVE_APPROVALS_FAILURE = 'FETCH_DYNAMIC_LEAVE_APPROVALS_FAILURE'
export const UPDATE_DYNAMIC_LEAVE_APPROVALS_SUCCESS = 'UPDATE_DYNAMIC_LEAVE_APPROVALS_SUCCESS'
export const UPDATE_DYNAMIC_LEAVE_APPROVALS_FAILURE = 'UPDATE_DYNAMIC_LEAVE_APPROVALS_FAILURE'
export const FETCH_AUTO_LEAVE_APPLY_RULE_SUCCESS = 'FETCH_AUTO_LEAVE_APPLY_RULE_SUCCESS'
export const FETCH_AUTO_LEAVE_APPLY_RULE_FAILURE = 'FETCH_AUTO_LEAVE_APPLY_RULE_FAILURE'
export const FETCH_AUTO_LEAVE_APPLY_SUCCESS = 'FETCH_AUTO_LEAVE_APPLY_SUCCESS'
export const CREATE_AUTO_LEAVE_APPLY_RULE_FAILURE = 'CREATE_AUTO_LEAVE_APPLY_RULE_FAILURE'
export const CREATE_AUTO_LEAVE_APPLY_RULE_SUCCESS = 'CREATE_AUTO_LEAVE_APPLY_RULE_SUCCESS'
export const UPDATE_AUTO_LEAVE_APPLY_RULE_FAILURE = 'UPDATE_AUTO_LEAVE_APPLY_RULE_FAILURE'
export const UPDATE_AUTO_LEAVE_APPLY_RULE_SUCCESS = 'UPDATE_AUTO_LEAVE_APPLY_RULE_SUCCESS'
export const DELETE_AUTO_LEAVE_APPLY_RULE_SUCCESS = 'DELETE_AUTO_LEAVE_APPLY_RULE_SUCCESS'
export const DELETE_AUTO_LEAVE_APPLY_RULE_FAILURE = 'DELETE_AUTO_LEAVE_APPLY_RULE_FAILURE'

//Permission Action Types
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS'
export const FETCH_PERMISSION_FAILURE = 'FETCH_PERMISSION_FAILURE'
export const FETCH_PERMISSIONS_OBJECT_SUCCESS = 'FETCH_PERMISSION_OBJECT_SUCCESS'
export const FETCH_PERMISSIONS_OBJECT_FAILURE = 'FETCH_PERMISSION_OBJECT_FAILURE'

//Role Action Types
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE'
export const RESET_ROLE_UPDATED = 'RESET_ROLE_UPDATED'
export const CLEAR_ROLE_DATA = 'CLEAR_ROLE_DATA'
export const LOADING_ROLE = 'LOADING_ROLE'
//Error Action Types
export const APP_ERROR_OCCURED = 'APP_ERROR_OCCURED'

//Department Action Types
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS'
export const CREATE_DEPARTMENT_FAILURE = 'CREATE_DEPARTMENT_FAILURE'
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS'
export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE'
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS'
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE'
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS'
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE'
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS'
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE'
export const FETCH_SELECT_DEPARTMENTS_SUCCESS = 'FETCH_SELECT_DEPARTMENTS_SUCCESS'
export const FETCH_SELECT_DEPARTMENTS_FAILURE = 'FETCH_SELECT_DEPARTMENTS_FAILURE'
export const FETCH_DEPARTMENTS_LIST_SUCCESS = 'FETCH_DEPARTMENTS_LIST_SUCCESS'
export const FETCH_DEPARTMENTS_LIST_FAILURE = 'FETCH_DEPARTMENTS_LIST_FAILURE'
export const FETCH_PUBLIC_DEPARTMENTS_SUCCESS = 'FETCH_PUBLIC_DEPARTMENTS_SUCCESS'
export const FETCH_PUBLIC_DEPARTMENTS_FAILURE = 'FETCH_PUBLIC_DEPARTMENTS_FAILURE'

export const UPDATE_USER_COMMENTS_SUCCESS = 'UPDATE_USER_COMMENTS_SUCCESS'
export const UPDATE_USER_COMMENTS_FAILURE = 'UPDATE_USER_COMMENTS_FAILURE'

//Sidebar Actions
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

//Title Actions
export const FETCH_TITLES_SUCCESS = 'FETCH_TITLES_SUCCESS'
export const FETCH_TITLES_FAILURE = 'FETCH_TITLES_FAILURE'
export const FETCH_SELECT_TITLES_SUCCESS = 'FETCH_SELECT_TITLES_SUCCESS'
export const FETCH_SELECT_TITLES_FAILURE = 'FETCH_SELECT_TITLES_FAILURE'
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS'
export const UPDATE_TITLE_FAILURE = 'UPDATE_TITLE_FAILURE'
export const BULK_UPDATE_TITLE_RANK_SUCCESS = 'BULK_UPDATE_TITLE_RANK_SUCCESS'
export const FETCH_TITLE_LOG_SUCCESS = 'FETCH_TITLE_LOG_SUCCESS'
export const FETCH_TITLE_LOG_FAILURE = 'FETCH_TITLE_LOG_FAILURE'

export const ENABLE_FORM_SUBMISSION = 'ENABLE_FORM_SUBMISSION'
export const DISABLE_FORM_SUBMISSION = 'DISABLE_FORM_SUBMISSION'

//Claims Action Types
export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS'
export const CREATE_CLAIM_FAILURE = 'CREATE_CLAIM_FAILURE'
export const FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS'
export const FETCH_CLAIMS_FAILURE = 'FETCH_CLAIMS_FAILURE'
export const SHOW_CLAIM_LOADER = 'SHOW_CLAIM_LOADER'
export const HIDE_CLAIM_LOADER = 'HIDE_CLAIM_LOADER'
export const SHOW_CLAIM_LIST_LOADER = 'SHOW_CLAIM_LIST_LOADER'
export const HIDE_CLAIM_LIST_LOADER = 'HIDE_CLAIM_LIST_LOADER'
export const SET_UPDATED_ASSIGNED_CLAIM = 'SET_UPDATED_ASSIGNED_CLAIM'
export const FETCH_CLAIM_APPROVALS_SUCCESS = 'FETCH_CLAIM_APPROVALS_SUCCESS'
export const FETCH_CLAIM_APPROVALS_FAILURE = 'FETCH_CLAIM_APPROVALS_FAILURE'
export const UPDATE_CLAIM_SUCCESS = 'UPDATE_CLAIM_SUCCESS'
export const UPDATE_CLAIM_FAILURE = 'UPDATE_CLAIM_FAILURE'
export const CLOSE_CLAIM_SUCCESS = 'CLOSE_CLAIM_SUCCESS'
export const CLOSE_CLAIM_FAILURE = 'CLOSE_CLAIM_FAILURE'
export const CANCEL_CLAIM_SUCCESS = 'CANCEL_CLAIM_SUCCESS'
export const CANCEL_CLAIM_FAILURE = 'CANCEL_CLAIM_FAILURE'
export const FETCH_ALL_CLAIMS_SUCCESS = 'FETCH_ALL_CLAIMS_SUCCESS'
export const FETCH_ALL_CLAIMS_FAILURE = 'FETCH_ALL_CLAIMS_FAILURE'
export const FETCH_DETAILED_CLAIMS_SUCCESS = 'FETCH_DETAILED_CLAIMS_SUCCESS'
export const FETCH_DETAILED_CLAIMS_FAILURE = 'FETCH_DETAILED_CLAIMS_FAILURE'
export const FETCH_CLAIMS_STATS_SUCCESS = 'FETCH_CLAIMS_STATS_SUCCESS'
export const FETCH_CLAIMS_STATS_FAILURE = 'FETCH_CLAIMS_STATS_FAILURE'
export const FETCH_CLAIM_CATEGORIES_SUCCESS = 'FETCH_CLAIM_CATEGORIES_SUCCESS'
export const FETCH_CLAIM_CATEGORIES_FAILURE = 'FETCH_CLAIM_CATEGORIES_FAILURE'
export const FETCH_CLAIM_SUCCESS = 'FETCH_CLAIM_SUCCESS'
export const FETCH_CLAIM_FAILURE = 'FETCH_CLAIM_FAILURE'
export const FETCH_TIME_SLOTS_SUCCESS = 'FETCH_TIME_SLOTS_SUCCESS'
export const FETCH_TIME_SLOTS_FAILURE = 'FETCH_TIME_SLOTS_FAILURE'
export const VERIFY_REDEEMABLE_SLOT_SUCCESS = 'VERIFY_REDEEMABLE_SLOT_SUCCESS'
export const VERIFY_REDEEMABLE_SLOT_FAILURE = 'VERIFY_REDEEMABLE_SLOT_FAILURE'
export const FETCH_CHILD_CLAIMS_SUCCESS = 'FETCH_CHILD_CLAIMS_SUCCESS'
export const FETCH_CHILD_CLAIMS_FAILURE = 'FETCH_CHILD_CLAIMS_FAILURE'
export const UPDATE_CLAIM_STATUS_SUCCESS = 'UPDATE_CLAIM_STATUS_SUCCESS'
export const UPDATE_CLAIM_STATUS_FAILURE = 'UPDATE_CLAIM_STATUS_FAILURE'
export const UPDATE_CLAIM_CATEGORY_SUCCESS = 'UPDATE_CLAIM_CATEGORY_SUCCESS'
export const UPDATE_CLAIM_CATEGORY_FAILURE = 'UPDATE_CLAIM_CATEGORY_FAILURE'
export const SET_CLAIMS_TAB = 'SET_CLAIMS_TAB'
export const SET_CLAIMS_PAGE_SIZE = 'SET_CLAIMS_PAGE_SIZE'

//Claim types
export const FETCH_CLAIM_TYPES_DETAILS_SUCCESS = 'FETCH_CLAIM_TYPES_DETAILS_SUCCESS'
export const FETCH_CLAIM_TYPES_DETAILS_FAILURE = 'FETCH_CLAIM_TYPES_DETAILS_FAILURE'
export const FETCH_CLAIM_TYPES_SUCCESS = 'FETCH_CLAIM_TYPES_SUCCESS'
export const FETCH_CLAIM_TYPES_FAILURE = 'FETCH_CLAIM_TYPES_FAILURE'
export const UPDATE_REDEEMED_ENTRY_SUCCESS = 'UPDATE_REDEEMED_ENTRY_SUCCESS'
export const UPDATE_REDEEMED_ENTRY_FAILURE = 'UPDATE_REDEEMED_ENTRY_FAILURE'
export const GET_REDEEMABLE_TIME_SLOTS_SUCCESS = 'GET_REDEEMABLE_TIME_SLOTS_SUCCESS'
export const GET_REDEEMABLE_TIME_SLOTS_FAILURE = 'GET_REDEEMABLE_TIME_SLOTS_FAILURE'

//Subscriptions
export const FETCH_REDEEMED_ENTRY_DATA_SUCCESS = 'FETCH_REDEEMED_ENTRY_DATA_SUCCESS'
export const FETCH_REDEEMED_ENTRY_DATA_FAILURE = 'FETCH_REDEEMED_ENTRY_DATA_FAILURE'
export const FETCH_ENTRY_COUNT_SUCCESS = 'FETCH_ENTRY_COUNT_SUCCESS'
export const FETCH_ENTRY_COUNT_FAILURE = 'FETCH_ENTRY_COUNT_FAILURE'
export const QR_SCAN_SUCCESS = 'QR_SCAN_SUCCESS'
export const FETCH_LIVE_COUNTER_SUCCESS = 'FETCH_LIVE_COUNTER_SUCCESS'

//Item Action Types
export const SET_ITEM_PAGE_SIZE = 'SET_ITEM_PAGE_SIZE'
export const SET_ITEMS_PAGE_SIZE = 'SET_ITEMS_PAGE_SIZE'
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS'
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE'
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS'
export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE'
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS'
export const CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE'
export const ITEM_CAPITALIZATION_SUCCESS = 'ITEM_CAPITALIZATION_SUCCESS'
export const FETCH_VENDOR_ITEM_SUCCESS = 'FETCH_VENDOR_ITEM_SUCCESS'
export const FETCH_ARCHIVED_ITEMS_SUCCESS = 'FETCH_ARCHIVED_ITEMS_SUCCESS'
export const FETCH_ARCHIVED_ITEMS_FAILURE = 'FETCH_ARCHIVED_ITEMS_FAILURE'
export const CLEAR_CREATE_ITEM_FORM_ERRORS = 'CLEAR_CREATE_ITEM_FORM_ERRORS'
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS'
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE'
export const RESET_ITEM_CREATED = 'RESET_ITEM_CREATED'
export const RESET_ITEM_UPDATED = 'RESET_ITEM_UPDATED'
export const ARCHIVE_ITEM_SUCCESS = 'ARCHIVE_ITEM_SUCCESS'
export const ARCHIVE_ITEM_FAILURE = 'ARCHIVE_ITEM_FAILURE'
export const UNARCHIVE_ITEM_SUCCESS = 'UNARCHIVE_ITEM_SUCCESS'
export const UNARCHIVE_ITEM_FAILURE = 'UNARCHIVE_ITEM_FAILURE'
export const ASSIGN_ITEM_SUCCESS = 'ASSIGN_ITEM_SUCCESS'
export const ASSIGN_ITEM_FAILURE = 'ASSIGN_ITEM_FAILURE'
export const SET_CLONED_ITEM_ID = 'SET_CLONED_ITEM_ID'
export const SET_CLONE_ITEM = 'SET_CLONE_ITEM'
export const RESET_CLONED_ITEM = 'RESET_CLONED_ITEM'
export const FETCH_USER_ITEMS_SUCCESS = 'FETCH_USER_ITEMS_SUCCESS'
export const FETCH_USER_ITEMS_FAILURE = 'FETCH_USER_ITEMS_FAILURE'
export const FETCH_TOTAL_ITEM_COUNT_SUCCESS = 'FETCH_TOTAL_ITEM_COUNT_SUCCESS'
export const FETCH_TOTAL_ITEM_COUNT_FAILURE = 'FETCH_TOTAL_ITEM_COUNT_FAILURE'
export const FETCH_SEARCHED_ITEMS_SUCCESS = 'FETCH_SEARCHED_ITEMS_SUCCESS'
export const FETCH_SEARCHED_ITEMS_FAILURE = 'FETCH_SEARCHED_ITEMS_FAILURE'
export const CLEAR_SEARCHED_ITEMS = 'CLEAR_SEARCHED_ITEMS'
export const FETCH_PENDING_ITEMS_COUNT_SUCCESS = 'FETCH_PENDING_ITEMS_COUNT_SUCCESS'
export const FETCH_PENDING_ITEMS_COUNT_FAILURE = 'FETCH_PENDING_ITEMS_COUNT_FAILURE'
export const CLEAR_ALL_ITEMS = 'CLEAR_ALL_ITEMS'
export const CLEAR_ALL_ARCHIVED_ITEMS = 'CLEAR_ALL_ARCHIVED_ITEMS'
export const CLEAR_CLONED_ITEM = 'CLEAR_CLONED_ITEM'
export const FETCH_FREE_ITEMS_SUCCESS = 'FETCH_FREE_ITEMS_SUCCESS'
export const FETCH_FREE_ITEMS_FAILURE = 'FETCH_FREE_ITEMS_FAILURE'
export const UPDATE_SUB_ITEMS_SUCCESS = 'UPDATE_SUB_ITEMS_SUCCESS'
export const UPDATE_SUB_ITEMS_FAILURE = 'UPDATE_SUB_ITEMS_FAILURE'
export const REMOVE_SUB_ITEM_SUCCESS = 'REMOVE_SUB_ITEM_SUCCESS'
export const REMOVE_SUB_ITEM_FAILURE = 'REMOVE_SUB_ITEM_FAILURE'
export const LOADING_ITEMS = 'LOADING_ITEMS'
export const FETCH_ITEMS_NAMES_SUCCESS = 'FETCH_ITEMS_NAMES_SUCCESS'
export const FETCH_ITEMS_NAMES_FAILURE = 'FETCH_ITEMS_NAMES_FAILURE'
export const FETCH_REPAIR_ITEMS_SUCCESS = 'FETCH_REPAIR_ITEMS_SUCCESS'
export const FETCH_ITEM_REPAIR_HISTORY_SUCCESS = 'FETCH_ITEM_REPAIR_HISTORY_SUCCESS'
export const ITEM_BULK_UPDATE_SUCCESS = 'ITEM_BULK_UPDATE_SUCCESS'
export const FETCH_RESELL_ITEMS_SUCCESS = 'FETCH_RESELL_ITEMS_SUCCESS'
export const FETCH_BULK_ITEM_CSV_ATTRIBUTES_SUCCESS = 'FETCH_BULK_ITEM_CSV_ATTRIBUTES_SUCCESS'
export const UPLOAD_BULK_ITEM_CSV_FAILURE = 'UPLOAD_BULK_ITEM_CSV_FAILURE'
export const UPLOAD_BULK_ITEM_CSV_SUCCESS = 'UPLOAD_BULK_ITEM_CSV_SUCCESS'
export const UPDATE_BULK_ITEM_CSV_ERRORS = 'UPDATE_BULK_ITEM_CSV_ERRORS'

//Category Action Types
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORY_FAILURE'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'
export const FETCH_CATEGORY_ALLOWED_ATTRIBUTE_SUCCESS = 'FETCH_CATEGORY_ALLOWED_ATTRIBUTE_SUCCESS'
export const CATEGORY_BULK_UPDATE_SUCCESS = 'CATEGORY_BULK_UPDATE_SUCCESS'

//Claims Blacklist Action Types
export const FETCH_CLAIMS_BLACKLIST_SUCCESS = 'FETCH_CLAIMS_BLACKLIST_SUCCESS'
export const FETCH_CLAIMS_BLACKLIST_FAILURE = 'FETCH_CLAIMS_BLACKLIST_FAILURE'
export const CREATE_CLAIMS_BLACKLIST_SUCCESS = 'CREATE_CLAIMS_BLACKLIST_SUCCESS'
export const CREATE_CLAIMS_BLACKLIST_FAILURE = 'CREATE_CLAIMS_BLACKLIST_FAILURE'
export const EDIT_CLAIMS_BLACKLIST_SUCCESS = 'EDIT_CLAIMS_BLACKLIST_SUCCESS'
export const EDIT_CLAIMS_BLACKLIST_FAILURE = 'EDIT_CLAIMS_BLACKLIST_FAILURE'
export const DELETE_CLAIMS_BLACKLIST_SUCCESS = 'DELETE_CLAIMS_BLACKLIST_SUCCESS'
export const DELETE_CLAIMS_BLACKLIST_FAILURE = 'DELETE_CLAIMS_BLACKLIST_FAILURE'

//Vendor Action Types
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS'
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE'
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS'
export const CREATE_VENDOR_FAILURE = 'CREATE_VENDOR_FAILURE'
export const EDIT_VENDOR_SUCCESS = 'EDIT_VENDOR_SUCCESS'
export const EDIT_VENDOR_FAILURE = 'EDIT_VENDOR_FAILURE'
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS'
export const DELETE_VENDOR_FAILURE = 'DELETE_VENDOR_FAILURE'
export const VENDORS_BULK_UPDATE_SUCCESS = 'VENDORS_BULK_UPDATE_SUCCESS'
export const FETCH_VENDORS_CSV_ATTRIBUTES_SUCCESS = 'FETCH_VENDORS_CSV_ATTRIBUTES_SUCCESS'

//Office Action Types
export const FETCH_OFFICE_SUCCESS = 'FETCH_OFFICE_SUCCESS'
export const FETCH_OFFICE_FAILURE = 'FETCH_OFFICE_FAILURE'
export const FETCH_SELECT_OFFICES_SUCCESS = 'FETCH_SELECT_OFFICES_SUCCESS'
export const CREATE_OFFICE_SUCCESS = 'CREATE_OFFICE_SUCCESS'
export const CREATE_OFFICE_FAILURE = 'CREATE_OFFICE_FAILURE'
export const EDIT_OFFICE_SUCCESS = 'EDIT_OFFICE_SUCCESS'
export const EDIT_OFFICE_FAILURE = 'EDIT_OFFICE_FAILURE'
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS'
export const DELETE_OFFICE_FAILURE = 'DELETE_OFFICE_FAILURE'
export const OFFICE_BULK_UPDATE_SUCCESS = 'OFFICE_BULK_UPDATE_SUCCESS'

//Item Allocation Action Types
export const FETCH_ITEM_ALLOCATION_SUCCESS = 'FETCH_ITEM_ALLOCATION_SUCCESS'
export const FETCH_ITEM_ALLOCATION_FAILURE = 'FETCH_ITEM_ALLOCATION_FAILURE'
export const CLEAR_ITEM_ALLOCATIONS = 'CLEAR_ITEM_ALLOCATIONS'
export const FETCH_ITEM_ALLOCATION_STATS_SUCCESS = 'FETCH_ITEM_ALLOCATION_STATS_SUCCESS'

//Item Logs
export const FETCH_ITEM_LOGS_SUCCESS = 'FETCH_ITEM_LOGS_SUCCESS'
export const FETCH_ITEM_LOGS_FAILURE = 'FETCH_ITEM_LOGS_FAILURE'
export const FETCH_ITEMS_LOGS_SUCCESS = 'FETCH_ITEMS_LOGS_SUCCESS'
export const FETCH_ITEMS_LOGS_FAILURE = 'FETCH_ITEMS_LOGS_FAILURE'
export const FETCH_SEARCHED_ITEM_LOGS_SUCCESS = 'FETCH_SEARCHED_ITEM_LOGS_SUCCESS'
export const FETCH_SEARCHED_ITEM_LOGS_FAILURE = 'FETCH_SEARCHED_ITEM_LOGS_FAILURE'
export const CLEAR_SEARCHED_ITEM_LOGS = 'CLEAR_SEARCHED_ITEM_LOGS'

//Item Purchases
export const REMOVE_ITEM_PURCHASES_SUCCESS = 'REMOVE_ITEM_PURCHASES_SUCCESS'
export const UPDATE_ITEM_PURCHASES_SUCCESS = 'UPDATE_ITEM_PURCHASES_SUCCESS'

//Item Stats
export const UPDATE_ITEM_STATS_SUCCESS = 'UPDATE_ITEM_STATS_SUCCESS'
export const UPDATE_ITEM_STATS_FAILURE = 'UPDATE_ITEM_STATS_FAILURE'

//Dnars Action Types
export const SHOW_DNARS_LOADER = 'SHOW_DNARS_LOADER'
export const HIDE_DNARS_LOADER = 'HIDE_DNARS_LOADER'
export const FETCH_USER_DNARS_BANK_SUCCESS = 'FETCH_USER_DNARS_BANK_SUCCESS'
export const FETCH_USER_DNARS_BANK_FAILURE = 'FETCH_USER_DNARS_BANK_FAILURE'
export const FETCH_DNAR_CATEGORIES_SUCCESS = 'FETCH_DNAR_CATEGORIES_SUCCESS'
export const FETCH_DNAR_CATEGORIES_FAILURE = 'FETCH_DNAR_CATEGORIES_FAILURE'
export const FETCH_PM_SUBORDINATES_SUCCESS = 'FETCH_PM_SUBORDINATES_SUCCESS'
export const FETCH_PM_SUBORDINATES_FAILURE = 'FETCH_PM_SUBORDINATES_FAILURE'
export const FETCH_PM_FUND_HISTORY_SUCCESS = 'FETCH_PM_FUND_HISTORY_SUCCESS'
export const FETCH_PM_FUND_HISTORY_FAILURE = 'FETCH_PM_FUND_HISTORY_FAILURE'
export const FETCH_PM_RECEIVE_HISTORY_SUCCESS = 'FETCH_PM_RECEIVE_HISTORY_SUCCESS'
export const FETCH_PM_RECEIVE_HISTORY_FAILURE = 'FETCH_PM_RECEIVE_HISTORY_FAILURE'
export const CREATE_POINTS_ALLOCATION_REQUEST_SUCCESS = 'CREATE_POINTS_ALLOCATION_REQUEST_SUCCESS'
export const CREATE_POINTS_ALLOCATION_REQUEST_FAILURE = 'CREATE_POINTS_ALLOCATION_REQUEST_FAILURE'
export const FETCH_PERSONAL_DNARS_HISTORY_SUCCESS = 'FETCH_PERSONAL_DNARS_HISTORY_SUCCESS'
export const FETCH_PERSONAL_DNARS_HISTORY_FAILURE = 'FETCH_PERSONAL_DNARS_HISTORY_FAILURE'
export const FETCH_PERSONAL_DNARS_STATS_SUCCESS = 'FETCH_PERSONAL_DNARS_STATS_SUCCESS'
export const FETCH_PERSONAL_DNARS_STATS_FAILURE = 'FETCH_PERSONAL_DNARS_STATS_FAILURE'
export const FETCH_TRANSFER_TO_PERSONAL_FUND_SUCCESS = 'FETCH_TRANSFER_TO_PERSONAL_FUND_SUCCESS'
export const FETCH_TRANSFER_TO_PERSONAL_FUND_FAILURE = 'FETCH_TRANSFER_TO_PERSONAL_FUND_FAILURE'
export const FETCH_ALL_USERS_DNARS_SUCCESS = 'FETCH_ALL_USERS_DNARS_SUCCESS'
export const FETCH_ALL_USERS_DNARS_FAILURE = 'FETCH_ALL_USERS_DNARS_FAILURE'
export const FETCH_REDEEM_DNARS_POLICIES_SUCCESS = 'FETCH_REDEEM_DNARS_POLICIES_SUCCESS'
export const FETCH_REDEEM_DNARS_POLICIES_FAILURE = 'FETCH_REDEEM_DNARS_POLICIES_FAILURE'
export const DELETE_REDEEM_DNARS_POLICY_SUCCESS = 'DELETE_REDEEM_DNARS_POLICY_SUCCESS'
export const DELETE_REDEEM_DNARS_POLICY_FAILURE = 'DELETE_REDEEM_DNARS_POLICY_FAILURE'
export const UPDATE_REDEEM_DNARS_POLICY_SUCCESS = 'UPDATE_REDEEM_DNARS_POLICY_SUCCESS'
export const UPDATE_REDEEM_DNARS_POLICY_FAILURE = 'UPDATE_REDEEM_DNARS_POLICY_FAILURE'
export const CREATE_REDEEM_DNARS_POLICY_SUCCESS = 'CREATE_REDEEM_DNARS_POLICY_SUCCESS'
export const CREATE_REDEEM_DNARS_POLICY_FAILURE = 'CREATE_REDEEM_DNARS_POLICY_FAILURE'

//Admin Panel Action Types
export const FETCH_CENTRAL_DNARS_BANK_SUCCESS = 'FETCH_CENTRAL_DNARS_BANK_SUCCESS'
export const FETCH_CENTRAL_DNARS_BANK_FAILURE = 'FETCH_CENTRAL_DNARS_BANK_FAILURE'
export const PRESERVE_MINTING_FORM_VALUES = 'PRESERVE_MINTING_FORM_VALUES'
export const GENERATE_MINTING_AUTH_SUCCESS = 'GENERATE_MINTING_AUTH_SUCCESS'
export const GENERATE_MINTING_AUTH_FAILURE = 'GENERATE_MINTING_AUTH_FAILURE'
export const VALIDATE_AUTH_TOKEN_SUCCESS = 'VALIDATE_AUTH_TOKEN_SUCCESS'
export const VALIDATE_AUTH_TOKEN_FAILURE = 'VALIDATE_AUTH_TOKEN_FAILURE'
export const UPDATE_AUTH_TOKEN_SUCCESS = 'UPDATE_AUTH_TOKEN_SUCCESS'
export const UPDATE_AUTH_TOKEN_FAILURE = 'UPDATE_AUTH_TOKEN_FAILURE'
export const CREATE_MINTING_REQUEST_SUCCESS = 'CREATE_MINTING_REQUEST_SUCCESS'
export const CREATE_MINTING_REQUEST_FAILURE = 'CREATE_MINTING_REQUEST_FAILURE'
export const FETCH_MINTING_HISTORY_SUCCESS = 'FETCH_MINTING_HISTORY_SUCCESS'
export const FETCH_MINTING_HISTORY_FAILURE = 'FETCH_MINTING_HISTORY_FAILURE'
export const FETCH_ROLLBACK_HISTORY_SUCCESS = 'FETCH_ROLLBACK_HISTORY_SUCCESS'
export const FETCH_ROLLBACK_HISTORY_FAILURE = 'FETCH_ROLLBACK_HISTORY_FAILURE'
export const FETCH_USER_TRANSACTIONS_SUCCESS = 'FETCH_USER_TRANSACTIONS_SUCCESS'
export const FETCH_USER_TRANSACTIONS_FAILURE = 'FETCH_USER_TRANSACTIONS_FAILURE'
export const FETCH_PENDING_TRANSACTIONS_SUCCESS = 'FETCH_PENDING_TRANSACTIONS_SUCCESS'
export const FETCH_PENDING_TRANSACTIONS_FAILURE = 'FETCH_PENDING_TRANSACTIONS_FAILURE'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE'
export const FETCH_PROCESSED_TRANSACTIONS_SUCCESS = 'FETCH_PROCESSED_TRANSACTIONS_SUCCESS'
export const FETCH_PROCESSED_TRANSACTIONS_FAILURE = 'FETCH_PROCESSED_TRANSACTIONS_FAILURE'
export const CREATE_DNARS_TRANSFER_REQUEST_SUCCESS = 'CREATE_DNARS_TRANSFER_REQUEST_SUCCESS'
export const CREATE_DNARS_TRANSFER_REQUEST_FAILURE = 'CREATE_DNARS_TRANSFER_REQUEST_FAILURE'

export const SET_FEEDBACKS_TAB = 'SET_FEEDBACKS_TAB'
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS'
export const CREATE_FEEDBACK_FAILURE = 'CREATE_FEEDBACK_FAILURE'
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS'
export const DELETE_FEEDBACK_FAILURE = 'DELETE_FEEDBACK_FAILURE'
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS'
export const FETCH_FEEDBACKS_FAILURE = 'FETCH_FEEDBACKS_FAILURE'

export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
export const FETCH_CSV_DATA_SUCCESS = 'FETCH_CSV_DATA_SUCCESS'
export const FETCH_CSV_DATA_FAILURE = 'FETCH_CSV_DATA_FAILURE'
export const UPLOAD_INVOICE_CSV_SUCCESS = 'UPLOAD_INVOICE_CSV_SUCCESS'
export const UPLOAD_INVOICE_CSV_FAILURE = 'UPLOAD_INVOICE_CSV_FAILURE'
export const FETCH_MONTHLY_INVOICE_DATA_SUCCESS = 'FETCH_MONTHLY_INVOICE_DATA_SUCCESS'
export const FETCH_MONTHLY_INVOICE_DATA_FAILURE = 'FETCH_MONTHLY_INVOICE_DATA_FAILURE'
export const SET_PROJECT_CREATED = 'SET_PROJECT_CREATED'
export const SET_PROJECTS_TAB = 'SET_PROJECTS_TAB'
export const UPDATE_PROJECT_STATUS_SUCCESS = 'UPDATE_PROJECT_STATUS_SUCCESS'
export const UPDATE_PROJECT_STATUS_FAILURE = 'UPDATE_PROJECT_STATUS_FAILURE'
export const FETCH_USER_PROJECTS_SUCCESS = 'FETCH_USER_PROJECTS_SUCCESS'
export const FETCH_USER_PROJECTS_FAILURE = 'FETCH_USER_PROJECTS_FAILURE'
export const FETCH_USERS_PROJECTS_SUCCESS = 'FETCH_USERS_PROJECTS_SUCCESS'
export const FETCH_USERS_PROJECTS_FAILURE = 'FETCH_USERS_PROJECTS_FAILURE'
export const CLEAR_USER_PROJECT_HISTORY = 'CLEAR_USER_PROJECT_HISTORY'
export const FETCH_USER_PROJECT_MEMBERS_SUCCESS = 'FETCH_USER_PROJECT_MEMBERS_SUCCESS'
export const FETCH_USER_PROJECT_MEMBERS_FAILURE = 'FETCH_USER_PROJECT_MEMBERS_FAILURE'
export const FETCH_USERS_FOR_PROJECT_SUCCESS = 'FETCH_USERS_FOR_PROJECT_SUCCESS'
export const FETCH_USERS_FOR_PROJECT_FAILURE = 'FETCH_USERS_FOR_PROJECT_FAILURE'
export const FETCH_USERS_FOR_PROJECT_MODULE_SUCCESS = 'FETCH_USERS_FOR_PROJECT_MODULE_SUCCESS'
export const FETCH_USERS_FOR_PROJECT_MODULE_FAILURE = 'FETCH_USERS_FOR_PROJECT_MODULE_FAILURE'
export const FETCH_USER_PROJECTS_FOR_CLAIMS_SUCCESS = 'FETCH_USER_PROJECTS_FOR_CLAIMS_SUCCESS'
export const FETCH_USER_PROJECTS_FOR_CLAIMS_FAILURE = 'FETCH_USER_PROJECTS_FOR_CLAIMS_FAILURE'
export const LOADING_PROJECT_LIST = 'LOADING_PROJECT_LIST'
export const FETCH_PROJECT_SERVICES_SUCCESS = 'FETCH_PROJECT_SERVICES_SUCCESS'

export const CREATE_PSEUDO_PROFILE_SUCCESS = 'CREATE_PSEUDO_PROFILE_SUCCESS'
export const CREATE_PSEUDO_PROFILE_FAILURE = 'CREATE_PSEUDO_PROFILE_FAILURE'
export const UPDATE_PSEUDO_PROFILE_SUCCESS = 'UPDATE_PSEUDO_PROFILE_SUCCESS'
export const UPDATE_PSEUDO_PROFILE_FAILURE = 'UPDATE_PSEUDO_PROFILE_FAILURE'
export const FETCH_PSEUDO_PROFILES_SUCCESS = 'FETCH_PSEUDO_PROFILES_SUCCESS'
export const FETCH_PSEUDO_PROFILES_FAILURE = 'FETCH_PSEUDO_PROFILES_FAILURE'
export const CREATE_PSEUDO_DESIGNATION_SUCCESS = 'CREATE_PSEUDO_DESIGNATION_SUCCESS'
export const CREATE_PSEUDO_DESIGNATION_FAILURE = 'CREATE_PSEUDO_DESIGNATION_FAILURE'
export const UPDATE_PSEUDO_DESIGNATION_SUCCESS = 'UPDATE_PSEUDO_DESIGNATION_SUCCESS'
export const UPDATE_PSEUDO_DESIGNATION_FAILURE = 'UPDATE_PSEUDO_DESIGNATION_FAILURE'
export const UPDATE_THREAD_STATUS_SUCCESS = 'UPDATE_THREAD_STATUS_SUCCESS'
export const UPDATE_THREAD_STATUS_FAILURE = 'UPDATE_THREAD_STATUS_FAILURE'
export const FETCH_PSEUDO_DESIGNATIONS_SUCCESS = 'FETCH_PSEUDO_DESIGNATIONS_SUCCESS'
export const FETCH_PSEUDO_DESIGNATIONS_FAILURE = 'FETCH_PROJECT_PSEUDO_DESIGNATIONS_FAILURE'

export const CREATE_PROJECT_MEMBER_SUCCESS = 'CREATE_PROJECT_MEMBER_SUCCESS'
export const CREATE_PROJECT_MEMBER_FAILURE = 'CREATE_PROJECT_MEMBER_FAILURE'
export const FETCH_PROJECT_MEMBERS_SUCCESS = 'FETCH_PROJECT_MEMBERS_SUCCESS'
export const FETCH_PROJECT_MEMBERS_FAILURE = 'FETCH_PROJECT_MEMBERS_FAILURE'
export const DELETE_PROJECT_MEMBER_SUCCESS = 'DELETE_PROJECT_MEMBER_SUCCESS'
export const DELETE_PROJECT_MEMBER_FAILURE = 'DELETE_PROJECT_MEMBER_FAILURE'
export const UPDATE_PROJECT_MEMBER_SUCCESS = 'UPDATE_PROJECT_MEMBER_SUCCESS'
export const UPDATE_PROJECT_MEMBER_FAILURE = 'UPDATE_PROJECT_MEMBER_FAILURE'
export const CLEAR_PROJECT_MEMBERS = 'CLEAR_PROJECT_MEMBERS'

export const FETCH_USER_PROJECTS_HISTORY_SUCCESS = 'FETCH_USER_PROJECTS_HISTORY_SUCCESS'
export const FETCH_USER_PROJECTS_HISTORY_FAILURE = 'FETCH_USER_PROJECTS_HISTORY_FAILURE'
export const CREATE_USER_PROJECTS_HISTORY_SUCCESS = 'CREATE_USER_PROJECTS_HISTORY_SUCCESS'
export const CREATE_USER_PROJECTS_HISTORY_FAILURE = 'CREATE_USER_PROJECTS_HISTORY_FAILURE'
export const UPDATE_USER_PROJECTS_HISTORY_SUCCESS = 'UPDATE_USER_PROJECTS_HISTORY_SUCCESS'
export const UPDATE_USER_PROJECTS_HISTORY_FAILURE = 'UPDATE_USER_PROJECTS_HISTORY_FAILURE'
export const DELETE_USER_PROJECTS_HISTORY_SUCCESS = 'DELETE_USER_PROJECTS_HISTORY_SUCCESS'
export const DELETE_USER_PROJECTS_HISTORY_FAILURE = 'DELETE_USER_PROJECTS_HISTORY_FAILURE'

export const FETCH_TECHNOLOGIES_SUCCESS = 'FETCH_TECHNOLOGIES_SUCCESS'
export const FETCH_TECHNOLOGIES_FAILURE = 'FETCH_TECHNOLOGIES_FAILURE'

export const FETCH_THREAD_TYPES_SUCCESS = 'FETCH_THREAD_TYPES_SUCCESS'

export const SET_REPORTS_TAB = 'SET_REPORTS_TAB'
export const FETCH_OVERVIEW_REPORT_SUCCESS = 'FETCH_OVERVIEW_REPORT_SUCCESS'
export const FETCH_OVERVIEW_REPORT_FAILURE = 'FETCH_OVERVIEW_REPORT_FAILURE'
export const FETCH_LEAVE_REPORT_SUCCESS = 'FETCH_LEAVE_REPORT_SUCCESS'
export const FETCH_LEAVE_REPORT_FAILURE = 'FETCH_LEAVE_REPORT_FAILURE'
export const FETCH_TICKET_REPORT_SUCCESS = 'FETCH_TICKET_REPORT_SUCCESS'
export const FETCH_TICKET_REPORT_FAILURE = 'FETCH_TICKET_REPORT_FAILURE'
export const FETCH_FEEDBACK_REPORT_SUCCESS = 'FETCH_FEEDBACK_REPORT_SUCCESS'
export const FETCH_FEEDBACK_REPORT_FAILURE = 'FETCH_FEEDBACK_REPORT_FAILURE'

export const SET_SETTINGS_TAB = 'SET_SETTINGS_TAB'
export const SET_EDIT_POLICY_ID = 'SET_EDIT_POLICY_ID'
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS'
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE'
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS'
export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE'

export const REMOVE_POLICIES = 'REMOVE_POLICIES'

export const GOOGLE_AUTH_FAILURE = 'GOOGLE_AUTH_FAILURE'
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS'
export const SET_LOGIN_ERRORS = 'SET_LOGIN_ERRORS'

export const DELETE_CLAIM_SUCCESS = 'DELETE_CLAIM_SUCCESS'
export const DELETE_CLAIM_FAILURE = 'DELETE_CLAIM_FAILURE'

export const SHOW_CLAIM_TYPE_LOADER = 'SHOW_CLAIM_TYPE_LOADER'
export const HIDE_CLAIM_TYPE_LOADER = 'HIDE_CLAIM_TYPE_LOADER'
export const SHOW_CLAIM_TYPE_FORM = 'SHOW_CLAIM_TYPE_FORM'
export const HIDE_CLAIM_TYPE_FORM = 'HIDE_CLAIM_TYPE_FORM'
export const HANDLE_SEARCHED_CLAIMS = 'HANDLE_SEARCHED_CLAIMS'

export const CREATE_CLAIM_TYPE_SUCCESS = 'CREATE_CLAIM_TYPE_SUCCESS'
export const CREATE_CLAIM_TYPE_FAILURE = 'CREATE_CLAIM_TYPE_FAILURE'
export const FETCH_CLAIM_TYPE_SUCCESS = 'FETCH_CLAIM_TYPE_SUCCESS'
export const FETCH_CLAIM_TYPE_FAILURE = 'FETCH_CLAIM_TYPE_FAILURE'
export const UPDATE_CLAIM_TYPE_SUCCESS = 'UPDATE_CLAIM_TYPE_SUCCESS'
export const UPDATE_CLAIM_TYPE_FAILURE = 'UPDATE_CLAIM_TYPE_FAILURE'

export const FETCH_HUBSTAFF_TIME_SUCCESS = 'FETCH_HUBSTAFF_TIME_SUCCESS'
export const FETCH_HUBSTAFF_TIME_FAILURE = 'FETCH_HUBSTAFF_TIME_FAILURE'

export const FETCH_TEAM_MEMBERS_SUCCESS = 'FETCH_TEAM_MEMBERS_SUCCESS'
export const FETCH_TEAM_MEMBERS_FAILURE = 'FETCH_TEAM_MEMBERS_FAILURE'
export const UPDATE_TEAM_MEMBERS_SUCCESS = 'UPDATE_TEAM_MEMBERS_SUCCESS'
export const UPDATE_TEAM_MEMBERS_FAILURE = 'UPDATE_TEAM_MEMBERS_FAILURE'
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS'
export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE'
export const FETCH_TEAM_CSV_ATTRIBUTES_SUCCESS = 'FETCH_TEAM_CSV_ATTRIBUTES_SUCCESS'

export const DISCONNECT_SLACK_FAILURE = 'DISCONNECT_SLACK_FAILURE'
export const DISCONNECT_SLACK_SUCCESS = 'DISCONNECT_SLACK_SUCCESS'

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_WITH_FILES_SUCCESS = 'FETCH_NOTIFICATIONS_WITH_FILES_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
export const READ_A_NOTIFICATION_SUCCESS = 'READ_A_NOTIFICATION_SUCCESS'
export const READ_A_NOTIFICATION_FAILURE = 'READ_A_NOTIFICATION_FAILURE'
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS'
export const MARK_ALL_AS_READ_FAILURE = 'MARK_ALL_AS_READ_FAILURE'
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = 'LOAD_MORE_NOTIFICATIONS_SUCCESS'
export const LOAD_MORE_NOTIFICATIONS_FAILURE = 'LOAD_MORE_NOTIFICATIONS_FAILURE'
export const SET_NOTIFICATIONS_PAGE_NUMBER = 'SET_NOTIFICATIONS_PAGE_NUMBER'
export const RECIEVED_REALTIME_NOTIFICATION = 'RECIEVED_REALTIME_NOTIFICATION'
export const FETCH_UNREAD_NOTIFICATIONS_SUCCESS = 'FETCH_UNREAD_NOTIFICATIONS_SUCCESS'
export const FETCH_UNREAD_NOTIFICATIONS_FAILURE = 'FETCH_UNREAD_NOTIFICATIONS_FAILURE'
export const SET_CALL_IN_PROGRESS = 'SET_CALL_IN_PROGRESS'
export const FETCH_NOTIFICATION_PREFERENCES_SUCCESS = 'FETCH_NOTIFICATION_PREFERENCES_SUCCESS'
export const FETCH_NOTIFICATION_PREFERENCES_FAILURE = 'FETCH_NOTIFICATION_PREFERENCES_FAILURE'
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS'
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_NOTIFICATION_PREFERENCES_FAILURE'

//Time Tracker Action Types
export const FETCH_TIME_TRACKER_WHITELISTED_USERS_SUCCESS = 'FETCH_TIME_TRACKER_WHITELISTED_USERS_SUCCESS'
export const FETCH_TIME_TRACKER_WHITELISTED_USERS_FAILURE = 'FETCH_TIME_TRACKER_WHITELISTED_USERS_FAILURE'
export const SET_TIME_TRACKER_WHITELISTED_USERS_SUCCESS = 'SET_TIME_TRACKER_WHITELISTED_USERS_SUCCESS'
export const SET_TIME_TRACKER_WHITELISTED_USERS_FAILURE = 'SET_TIME_TRACKER_WHITELISTED_USERS_FAILURE'

// HA Dashboard Types
export const FETCH_DELEGATED_USER_SUCCESS = 'FETCH_DELEGATED_USER_SUCCESS'
export const FETCH_DELEGATED_USER_FAILURE = 'FETCH_DELEGATED_USER_FAILURE'
export const UPDATE_DELEGATED_USER_SUCCESS = 'UPDATE_DELEGATED_USER_SUCCESS'
export const UPDATE_DELEGATED_USER_FAILURE = 'UPDATE_DELEGATED_USER_FAILURE'
export const FETCH_USER_REVIEW_TIMELINE_SUCCESS = 'FETCH_USER_REVIEW_TIMELINE_SUCCESS'

// Admin Setting Tab Review Setting
export const FETCH_REVIEW_SETTINGS_SUCCESS = 'FETCH_REVIEW_SETTINGS_SUCCESS'
export const FETCH_REVIEW_SETTINGS_FAILURE = 'FETCH_REVIEW_SETTINGS_FAILURE'
export const SHOW_REVIEW_SETTING_LOADER = 'SHOW_REVIEW_SETTING_LOADER'
export const FETCH_REVIEW_SETTING_SUCCESS = 'FETCH_REVIEW_SETTING_SUCCESS'
export const FETCH_REVIEW_SETTING_FAILURE = 'FETCH_REVIEW_SETTING_FAILURE'
export const CREATE_REVIEW_SETTING_SUCCESS = 'CREATE_REVIEW_SETTING_SUCCESS'
export const CREATE_REVIEW_SETTING_FAILURE = 'CREATE_REVIEW_SETTING_FAILURE'
export const DELETE_REVIEW_SETTING_SUCCESS = 'DELETE_REVIEW_SETTING_SUCCESS'
export const DELETE_REVIEW_SETTING_FAILURE = 'DELETE_REVIEW_SETTING_FAILURE'
export const UPDATE_REVIEW_SETTING_SUCCESS = 'UPDATE_REVIEW_SETTING_SUCCESS'
export const UPDATE_REVIEW_SETTING_FAILURE = 'UPDATE_REVIEW_SETTING_FAILURE'

// Admin Setting Pre Onboarding Setting
export const FETCH_INVITE_SETTINGS_SUCCESS = 'FETCH_INVITE_SETTINGS_SUCCESS'
export const FETCH_INVITE_SETTINGS_FAILURE = 'FETCH_INVITE_SETTINGS_FAILURE'
export const FETCH_INVITE_SETTING_SUCCESS = 'FETCH_INVITE_SETTING_SUCCESS'
export const FETCH_INVITE_SETTING_FAILURE = 'FETCH_INVITE_SETTING_FAILURE'
export const CREATE_INVITE_SETTING_SUCCESS = 'CREATE_INVITE_SETTING_SUCCESS'
export const CREATE_INVITE_SETTING_FAILURE = 'CREATE_INVITE_SETTING_FAILURE'
export const UPDATE_INVITE_SETTING_SUCCESS = 'UPDATE_INVITE_SETTING_SUCCESS'
export const UPDATE_INVITE_SETTING_FAILURE = 'UPDATE_INVITE_SETTING_FAILURE'

export const FETCH_COMPANY_FEATURED_PAGES_SUCCESS = 'FETCH_COMPANY_FEATURED_PAGES_SUCCESS'
export const FETCH_COMPANY_FEATURED_PAGE_SUCCESS = 'FETCH_COMPANY_FEATURED_PAGE_SUCCESS'
export const CREATE_COMPANY_FEATURED_PAGE_SUCCESS = 'CREATE_COMPANY_FEATURED_PAGE_SUCCESS'
export const UPDATE_COMPANY_FEATURED_PAGE_SUCCESS = 'UPDATE_COMPANY_FEATURED_PAGE_SUCCESS'
export const DELETE_COMPANY_FEATURED_PAGE_SUCCESS = 'DELETE_COMPANY_FEATURED_PAGE_SUCCESS'
// Event Types
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE'

// Workflows Categories
export const FETCH_WORKFLOW_CATEGORIES_SUCCESS = 'FETCH_WORKFLOW_CATEGORIES_SUCCESS'
export const FETCH_WORKFLOW_CATEGORIES_FAILURE = 'FETCH_WORKFLOW_CATEGORIES_FAILURE'
export const FETCH_WORKFLOW_CATEGORY_SUCCESS = 'FETCH_WORKFLOW_CATEGORY_SUCCESS'
export const FETCH_WORKFLOW_CATEGORY_FAILURE = 'FETCH_WORKFLOW_CATEGORY_FAILURE'
export const UPDATE_WORKFLOW_CATEGORY_SUCCESS = 'UPDATE_WORKFLOW_CATEGORY_SUCCESS'
export const UPDATE_WORKFLOW_CATEGORY_FAILURE = 'UPDATE_WORKFLOW_CATEGORY_FAILURE'
export const DELETE_WORKFLOW_CATEGORY_SUCCESS = 'DELETE_WORKFLOW_CATEGORY_SUCCESS'
export const DELETE_WORKFLOW_CATEGORY_FAILURE = 'DELETE_WORKFLOW_CATEGORY_FAILURE'
export const CREATE_WORKFLOW_CATEGORY_SUCCESS = 'CREATE_WORKFLOW_CATEGORY_SUCCESS'
export const CREATE_WORKFLOW_CATEGORY_FAILURE = 'CREATE_WORKFLOW_CATEGORY_FAILURE'

// Workflows
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS'
export const CREATE_WORKFLOW_FAILURE = 'CREATE_WORKFLOW_FAILURE'
export const FETCH_WORKFLOWS_SUCCESS = 'FETCH_WORKFLOWS_SUCCESS'
export const FETCH_WORKFLOWS_FAILURE = 'FETCH_WORKFLOWS_FAILURE'
export const FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS'
export const FETCH_WORKFLOW_FAILURE = 'FETCH_WORKFLOW_FAILURE'
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS'
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE'
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS'
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE'

// Tasks
export const CREATE_WORKFLOW_TASKS_SUCCESS = 'CREATE_WORKFLOW_TASKS_SUCCESS'
export const CREATE_WORKFLOW_TASKS_FAILURE = 'CREATE_WORKFLOW_TASKS_FAILURE'
export const FETCH_WORKFLOW_TASK_SUCCESS = 'FETCH_WORKFLOW_TASK_SUCCESS'
export const FETCH_WORKFLOW_TASK_FAILURE = 'FETCH_WORKFLOW_TASK_FAILURE'
export const DELETE_WORKFLOW_TASK_SUCCESS = 'DELETE_WORKFLOW_TASK_SUCCESS'
export const DELETE_WORKFLOW_TASK_FAILURE = 'DELETE_WORKFLOW_TASK_FAILURE'
export const UPDATE_WORKFLOW_TASK_SUCCESS = 'UPDATE_WORKFLOW_TASK_SUCCESS'
export const UPDATE_WORKFLOW_TASK_FAILURE = 'UPDATE_WORKFLOW_TASK_FAILURE'

export const FETCH_USER_TASKS_SUCCESS = 'FETCH_USER_TASKS_SUCCESS'
export const FETCH_USER_TASKS_FAILURE = 'FETCH_USER_TASKS_FAILURE'
export const FETCH_USER_TASK_SUCCESS = 'FETCH_USER_TASK_SUCCESS'
export const FETCH_USER_TASK_FAILURE = 'FETCH_USER_TASK_FAILURE'
export const UPDATE_USER_TASK_SUCCESS = 'UPDATE_USER_TASK_SUCCESS'
export const UPDATE_USER_TASK_FAILURE = 'UPDATE_USER_TASK_FAILURE'
export const FETCH_SELECT_TASKS_OPTIONS_SUCCESS = 'FETCH_SELECT_TASKS_OPTIONS_SUCCESS'

// TASK DYNAMIC FORM RESPONSE
export const CREATE_TASK_DYNAMIC_FORM_SUCCESS = 'CREATE_TASK_DYNAMIC_FORM_SUCCESS'
export const CREATE_TASK_DYNAMIC_FORM_FAILURE = 'CREATE_TASK_DYNAMIC_FORM_FAILURE'

// Admin Setting Tab Dynamic Forms
export const CREATE_DYNAMIC_FORM_SUCCESS = 'CREATE_DYNAMIC_FORM_SUCCESS'
export const CREATE_DYNAMIC_FORM_FAILURE = 'CREATE_DYNAMIC_FORM_FAILURE'
export const UPDATE_DYNAMIC_FORM_SUCCESS = 'UPDATE_DYNAMIC_FORM_SUCCESS'
export const UPDATE_DYNAMIC_FORM_FAILURE = 'UPDATE_DYNAMIC_FORM_FAILURE'
export const DELETE_DYNAMIC_FORM_SUCCESS = 'DELETE_DYNAMIC_FORM_SUCCESS'
export const DELETE_DYNAMIC_FORM_FAILURE = 'DELETE_DYNAMIC_FORM_FAILURE'
export const FETCH_DYNAMIC_FORMS_SUCCESS = 'FETCH_DYNAMIC_FORMS_SUCCESS'
export const FETCH_DYNAMIC_FORMS_FAILURE = 'FETCH_DYNAMIC_FORMS_FAILURE'
export const FETCH_DYNAMIC_FORM_SUCCESS = 'FETCH_DYNAMIC_FORM_SUCCESS'
export const FETCH_DYNAMIC_FORM_FAILURE = 'FETCH_DYNAMIC_FORM_FAILURE'
export const SHOW_DYNAMIC_FORM_LOADER = 'SHOW_DYNAMIC_FORM_LOADER'
export const HIDE_DYNAMIC_FORM_LOADER = 'HIDE_DYNAMIC_FORM_LOADER'
export const UPDATE_DRAFT_DYNAMIC_FORM = 'UPDATE_DRAFT_DYNAMIC_FORM'
export const FETCH_DYNAMIC_FORM_CSV_SUCCESS = 'FETCH_DYNAMIC_FORM_CSV_SUCCESS'
export const FETCH_DYNAMIC_FORM_RESPONSE_SUCCESS = 'FETCH_DYNAMIC_FORM_RESPONSE_SUCCESS'
export const UPDATE_DYNAMIC_FORM_RESPONSE_SUCCESS = 'UPDATE_DYNAMIC_FORM_RESPONSE_SUCCESS'

//Question Action Types
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAILURE = 'CREATE_QUESTION_FAILURE'
export const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS'
export const FETCH_QUESTION_FAILURE = 'FETCH_QUESTION_FAILURE'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE'
export const FETCH_QUESTION_BY_ID_SUCCESS = 'FETCH_QUESTION_BY_ID_SUCCESS'
export const FETCH_QUESTION_BY_ID_FAILURE = 'FETCH_QUESTION_BY_ID_FAILURE'
export const SHOW_QUESTION_LOADER = 'SHOW_QUESTION_LOADER'
export const HIDE_QUESTION_LOADER = 'HIDE_QUESTION_LOADER'

// SUBSCRIPTION PANEL REPORTS
export const FETCH_SUBSCRIPTIONS_REPORT_SUCCESS = 'FETCH_SUBSCRIPTIONS_REPORT_SUCCESS'
export const FETCH_SUBSCRIPTIONS_REPORT_FAILURE = 'FETCH_SUBSCRIPTIONS_REPORT_FAILURE'
export const FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_SUCCESS = 'FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_SUCCESS'
export const FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_FAILURE = 'FETCH_SUBSCRIPTIONS_REDEEMED_REPORT_FAILURE'
export const FETCH_PEAK_HOURS_REPORTS_SUCCESS = 'FETCH_PEAK_HOURS_REPORTS_SUCCESS'
export const FETCH_PEAK_HOURS_REPORTS_FAILURE = 'FETCH_PEAK_HOURS_REPORTS_FAILURE'
export const UPDATE_SUBSCRIPTION_BUFFER_SUCCESS = 'UPDATE_SUBSCRIPTION_BUFFER_SUCCESS'
export const UPDATE_SUBSCRIPTION_BUFFER_FAILURE = 'UPDATE_SUBSCRIPTION_BUFFER_FAILURE'
export const FETCH_SUBSCRIPTION_BUFFER_SUCCESS = 'FETCH_SUBSCRIPTION_BUFFER_SUCCESS'
export const FETCH_SUBSCRIPTION_BUFFER_FAILURE = 'FETCH_SUBSCRIPTION_BUFFER_FAILURE'

//Slack Announcement
export const FETCH_ANNOUNCEMENT_SETTINGS_SUCCESS = 'FETCH_ANNOUNCEMENT_SETTINGS_SUCCESS'
export const FETCH_ANNOUNCEMENT_SETTINGS_FAILURE = 'FETCH_ANNOUNCEMENT_SETTINGS_FAILURE'
export const FETCH_ANNOUNCEMENT_SETTING_SUCCESS = 'FETCH_ANNOUNCEMENT_SETTING_SUCCESS'
export const FETCH_ANNOUNCEMENT_FAILURE_FAILURE = 'FETCH_ANNOUNCEMENT_FAILURE_FAILURE'
export const DELETE_ANNOUNCEMENT_SETTING_SUCCESS = 'DELETE_ANNOUNCEMENT_SETTING_SUCCESS'
export const DELETE_ANNOUNCEMENT_SETTING_FAILURE = 'DELETE_ANNOUNCEMENT_SETTING_FAILURE'
export const UPDATE_ANNOUNCEMENT_SETTING_SUCCESS = 'UPDATE_ANNOUNCEMENT_SETTING_SUCCESS'
export const UPDATE_ANNOUNCEMENT_SETTING_FAILURE = 'UPDATE_ANNOUNCEMENT_SETTING_FAILURE'
export const CREATE_ANNOUNCEMENT_SETTING_SUCCESS = 'CREATE_ANNOUNCEMENT_SETTING_SUCCESS'
export const CREATE_ANNOUNCEMENT_SETTING_FAILURE = 'CREATE_ANNOUNCEMENT_SETTING_FAILURE'
export const FETCH_ANNOUNCEMENT_CHANNELS_SUCCESS = 'FETCH_ANNOUNCEMENT_CHANNELS_SUCCESS'
export const FETCH_ANNOUNCEMENT_CHANNELS_FAILURE = 'FETCH_ANNOUNCEMENT_CHANNELS_FAILURE'

//Invite
export const USERS_INVITE_FAILURE = 'USERS_INVITE_FAILURE'
export const USERS_INVITE_SUCCESS = 'USERS_INVITE_SUCCESS'

// ANNOUNCEMENT APPROVALS
export const FETCH_ALL_ANNOUNCEMENTS_FAILURE = 'FETCH_ALL_ANNOUNCEMENTS_FAILURE'
export const FETCH_ALL_ANNOUNCEMENTS_SUCCESS = 'FETCH_ALL_ANNOUNCEMENTS_SUCCESS'
export const UPDATE_ANNOUNCEMENT_STATUS_FAILURE = 'UPDATE_ANNOUNCEMENT_STATUS_FAILURE'
export const BULK_UPDATE_REVIEW_STATUS_SUCCESS = 'BULK_UPDATE_REVIEW_STATUS_SUCCESS'
export const UPDATE_ANNOUNCEMENT_STATUS_SUCCESS = 'UPDATE_ANNOUNCEMENT_STATUS_SUCCESS'
export const UPDATE_ANNOUNCEMENTS_STATUS_FAILURE = 'UPDATE_ANNOUNCEMENTS_STATUS_FAILURE'
export const UPDATE_ANNOUNCEMENTS_STATUS_SUCCESS = 'UPDATE_ANNOUNCEMENTS_STATUS_SUCCESS'

// Education
export const FETCH_EDUCATION_MAJOR_SUCCESS = 'FETCH_EDUCATION_MAJOR_SUCCESS'
export const CREATE_EDUCATION_MAJOR_SUCCESS = 'CREATE_EDUCATION_MAJOR_SUCCESS'
export const FETCH_EDUCATION_DEGREE_SUCCESS = 'FETCH_EDUCATION_DEGREE_SUCCESS'
export const CREATE_EDUCATION_DEGREE_SUCCESS = 'CREATE_EDUCATION_DEGREE_SUCCESS'

// INVENTORY OVERVIEW
export const FETCH_INVENTORY_GRAPH_DATA_SUCCESS = 'FETCH_INVENTORY_GRAPH_DATA_SUCCESS'
// Industries
export const FETCH_INDUSTRIES_SUCCESS = 'FETCH_INDUSTRIES_SUCCESS'
export const FETCH_INDUSTRIES_FAILURE = 'FETCH_INDUSTRIES_FAILURE'

// WORKFLOW REPORTS
export const FETCH_WORKFLOW_REPORTS_DATA_SUCCESS = 'FETCH_WORKFLOW_REPORTS_DATA_SUCCESS'
export const FETCH_SELECT_WORKFLOW_SUCCESS = 'FETCH_SELECT_WORKFLOW_SUCCESS'
export const FETCH_SELECT_REPORTING_USERS_SUCCESS = 'FETCH_SELECT_REPORTING_USERS_SUCCESS'
export const UPDATE_WORKFLOW_REPORT_DATA_SUCCESS = 'UPDATE_WORKFLOW_REPORT_DATA_SUCCESS'

// PROJECT TIMELINE
export const FETCH_PROJECT_TIMELINE_SUCCESS = 'FETCH_PROJECT_TIMELINE_SUCCESS'
export const FETCH_SELECT_PSEUDO_PROFILE_SUCCESS = 'FETCH_SELECT_PSEUDO_PROFILE_SUCCESS'
export const FETCH_SELECT_TIMELINE_PROJECTS_SUCCESS = 'FETCH_SELECT_TIMELINE_PROJECTS_SUCCESS'

// Attendance
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS'
export const FETCH_ATTENDANCE_FAILURE = 'FETCH_ATTENDANCE_FAILURE'
export const MARK_ATTENDANCE_SUCCESS = 'MARK_ATTENDANCE_SUCCESS'
export const MARK_ATTENDANCE_FAILURE = 'MARK_ATTENDANCE_FAILURE'

export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'

// NATIONALITY
export const FETCH_NATIONALITIES_SUCCESS = 'FETCH_NATIONALITIES_SUCCESS'

//EXIT REASONS
export const FETCH_USER_EXIT_REASONS_SUCCESS = 'FETCH_USER_EXIT_REASONS_SUCCESS'
export const CREATE_USER_EXIT_REASON_SUCCESS = 'CREATE_USER_EXIT_REASON_SUCCESS'

export const FETCH_DOCUMENT_SETTINGS_SUCCESS = 'FETCH_DOCUMENT_SETTINGS_SUCCESS'
export const UPDATE_DOCUMENT_SETTING_SUCCESS = 'UPDATE_DOCUMENT_SETTING_SUCCESS'
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS'

// PEOPLE CUSTOM REPORT
export const CREATE_PEOPLE_CUSTOM_REPORT_SUCCESS = 'CREATE_PEOPLE_CUSTOM_REPORT_SUCCESS'
export const FETCH_PEOPLE_CUSTOM_REPORTS_SUCCESS = 'FETCH_PEOPLE_CUSTOM_REPORTS_SUCCESS'
export const DELETE_PEOPLE_CUSTOM_REPORT_SUCCESS = 'DELETE_PEOPLE_CUSTOM_REPORT_SUCCESS'
export const DOWNLOAD_PEOPLE_CUSTOM_REPORT_SUCCESS = 'DOWNLOAD_PEOPLE_CUSTOM_REPORT_SUCCESS'
export const FETCH_CUSTOM_REPORT_STATS_SUCCESS = 'FETCH_CUSTOM_REPORT_STATS_SUCCESS'
export const FETCH_REVIEW_REPORT_STATS_SUCCESS = 'FETCH_REVIEW_REPORT_STATS_SUCCESS'
export const FETCH_TICKET_CARDS_REPORT_SUCCESS = 'FETCH_TICKET_CARDS_REPORT_SUCCESS'
export const FETCH_TICKET_DEPARTMENT_REPORT_SUCCESS = 'FETCH_TICKET_DEPARTMENT_REPORT_SUCCESS'
export const FETCH_TICKET_CATEGORIES_REPORT_SUCCESS = 'FETCH_TICKET_CATEGORIES_REPORT_SUCCESS'
export const FETCH_TICKET_ASSIGNEE_REPORT_SUCCESS = 'FETCH_TICKET_ASSIGNEE_REPORT_SUCCESS'

// Third Party Vendor
export const FETCH_THIRD_PARTY_VENDOR_SUCCESS = 'FETCH_THIRD_PARTY_VENDOR_SUCCESS'

// PMS Actions Types
export const FETCH_REVIEW_CONFIG_SUCCESS = 'FETCH_REVIEW_CONFIG_SUCCESS'
export const FETCH_REVIEW_CONFIGURATION_SUCCESS = 'FETCH_REVIEW_CONFIGURATION_SUCCESS'
export const CREATE_REVIEW_CONFIG_SUCCESS = 'CREATE_REVIEW_CONFIG_SUCCESS'
export const SHOW_PMS_LOADER = 'SHOW_PMS_LOADER'
export const UPDATE_REVIEW_CONFIG_SUCCESS = 'UPDATE_REVIEW_CONFIG_SUCCESS'
export const DELETE_REVIEW_CONFIG_SUCCESS = 'DELETE_REVIEW_CONFIG_SUCCESS'
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS'

// PMS MANAGER SIDE ACTION TYPES
export const FETCH_GOAL_AND_KPI_SUCCESS = 'FETCH_GOAL_AND_KPI_SUCCESS'
export const CREATE_IMPACT_AREA_SUCCESS = 'CREATE_IMPACT_AREA_SUCCESS'
export const DELETE_IMPACT_AREA_SUCCESS = 'DELETE_IMPACT_AREA_SUCCESS'
export const UPDATE_IMPACT_AREA_SUCCESS = 'UPDATE_IMPACT_AREA_SUCCESS'
export const FETCH_SELECT_GOAL_KPI_SUCCESS = 'FETCH_SELECT_GOAL_KPI_SUCCESS'
export const FETCH_REVIEW_IMPACT_AREAS_SUCCESS = 'FETCH_REVIEW_IMPACT_AREAS_SUCCESS'
export const FETCH_PMS_REVIEW_SETTINGS_SUCCESS = 'FETCH_PMS_REVIEW_SETTINGS_SUCCESS'
export const UPDATE_SETTING_IMPACT_AREA_SUCCESS = 'UPDATE_SETTING_IMPACT_AREA_SUCCESS'
export const FETCH_TEAMMATES_REVIEWS_SUCCESS = 'FETCH_TEAMMATES_REVIEWS_SUCCESS'
export const CHANGE_REVIEWER_SUCCESS = 'CHANGE_REVIEWER_SUCCESS'
export const FETCH_REVIEW_DETAIL_TIMELINE_SUCCESS = 'FETCH_REVIEW_DETAIL_TIMELINE_SUCCESS'
export const FETCH_USER_REVIEW_SYNCS_SUCCESS = 'FETCH_USER_REVIEW_SYNCS_SUCCESS'
export const FETCH_REVIEW_SYNC_DETAIL_SUCCESS = 'FETCH_REVIEW_SYNC_DETAIL_SUCCESS'

// PMS REQUIRE INTEGRATION
export const FETCH_USER_DETAILED_REVIEW_SUCCESS = 'FETCH_USER_DETAILED_REVIEW_SUCCESS'
export const SUBMIT_MANAGER_REVIEW_FEEDBACK_SUCCESS = 'SUBMIT_MANAGER_REVIEW_FEEDBACK_SUCCESS'
export const UPDATE_REVIEW_SYNC_SUCCESS = 'UPDATE_REVIEW_SYNC_SUCCESS'
//
