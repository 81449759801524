import React from 'react'
import classNames from 'classnames'
import { upperCase } from 'lodash'
import { Avatar, Badge, IconButton, Tooltip, Typography } from 'maple-storybook'

import { avatarImageProps, formatLongText } from 'helpers/applicationHelper'
import { getUserOnBoardingID, onBoardingUserID } from 'helpers/userHelper'

import './style.scss'

const AvatarWithLabelOption = ({ avatar, label, id, compact, custom, sourceType, displayId = true, ...props }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    window.open(`/people/${id}`, '_blank')
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={
        displayId && label?.length > 17 && id
          ? `${label} ${getUserOnBoardingID(id)}`
          : label?.length > 17
            ? `${label}`
            : ''
      }>
      <div className="d-flex align-items-center dropdown-main-row">
        <IconButton
          onClick={onBoardingUserID(id) ? null : handleClick}
          className="avatar-icon-button"
          centerRipple={false}>
          {avatar && (
            <Avatar
              className={`dropdown-avatar ${props.userOffboarded ? 'offboarded-opacity' : ''}`}
              src={avatar}
              name={label}
              alt={label}
              imgProps={avatarImageProps()}
            />
          )}
        </IconButton>
        {!compact && (
          <div className="label-id flex-column">
            <strong>
              <span
                className={`dropdown-label-text ${custom ? classNames(props.labelClassName.className) : ''} ${props.userOffboarded ? 'off-boarded-color' : ''}`}>
                {formatLongText(label, 17)}
              </span>
            </strong>
            {id && (
              <label
                className={`user-id-text ${props.userOffboarded ? 'off-boarded-color' : ''}`}>{`${getUserOnBoardingID(id)}`}</label>
            )}
            {props.userOffboarded && (
              <Badge label={'Off-boarded'} variant="secondary" className="user-status-chip_list" />
            )}
            {sourceType && (
              <Typography variant="text-sm" type="normal" color="#009281">
                {upperCase(sourceType)}
              </Typography>
            )}
          </div>
        )}
      </div>
    </Tooltip>
  )
}
export default AvatarWithLabelOption
