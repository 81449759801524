import { Fragment, lazy, Suspense, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import { Navigate, Route, Routes as Switch, useLocation, useNavigate } from 'react-router-dom'
import { Provider } from '@rollbar/react'
import classNames from 'classnames'
import BlurOverlay from 'containers/BlurOverlay'
import { isEmpty } from 'lodash'
import { ThemeProvider, ToastProvider, Typography } from 'maple-storybook'
import { parse } from 'qs'

// import { MapleBotWidget } from 'views/Dashboard/Widgets/MapleBotWidget'
import { fetchCurrentCompanyFailure, fetchCurrentCompanySuccess } from 'actions/company'
import { fetchPermissionFailure, fetchPermissionSuccess } from 'actions/permission'
import { fetchDayFeedbackFailure, fetchDayFeedbackSuccess, getProfileFailure, getProfileSuccess } from 'actions/user'
import { fetchCurrentCompanies } from 'api/company'
import { fetchPermissions } from 'api/permission'
import { fetchDayFeedback, getProfile } from 'api/user'
import { DayFeedback, Delay, ErrorBoundaryWrapper, Footer, Header, Overlay, Sidebar } from 'components'
import { BottomActionBar } from 'components/BottomActionBar'
import ModalRoot from 'components/Modals/ModalRoot'
import { unprotectedPages } from 'config/unprotectedPagesConfig'
import { handleModalOffset, handleReceiveMessage, isImpersonateMode } from 'helpers/applicationHelper'
import { setImpersonateData } from 'helpers/authHelper'
import { checkPermission } from 'helpers/permissionsHelper'

import Backdrop from './Backdrop'
import { appTheme } from './MuiTheme'
import Routes from './Routes'

import './styles/AppStyles.css'

const rollbarConfig = (user) => {
  return import.meta.env.REACT_APP_ROLLBAR_KEY
    ? {
        accessToken: import.meta.env.REACT_APP_ROLLBAR_KEY,
        environment: `fe-${window.location.hostname.split('.')[0]}`,
        captureUncaught: true,
        captureUnhandledRejections: false,
        payload: {
          person: {
            id: user?.userId,
            username: user?.userName
          }
        },
        ignoredMessages: [
          'maplehr.io/static/',
          'received',
          'dynamically imported module',
          'ResizeObserver loop',
          'this.options.source',
          'blur on Window'
        ]
      }
    : {}
}

const ForgotPasswordPage = lazy(() => import('../../views/Landing/ForgotPasswordPage'))
const LandingPage = lazy(() => import('../../views/Landing/Landing'))
const ResetPasswordPage = lazy(() => import('../../views/Landing/ResetPasswordPage'))
const UserPreboardingFlow = lazy(() => import('../../views/Users/UserPreboardingFlow'))

const cannotRenderApp = (isUserLoading, { isSignedIn, userId, permissions }) => {
  if (isUserLoading()) {
    return true
  }

  if (isSignedIn && userId && (permissions.admin || permissions.length !== 0)) {
    return false
  }

  return !isSignedIn && !userId ? !(localStorage.getItem('access-token') == null) : true
}

function App({
  isSignedIn,
  permissions,
  company,
  userId,
  isLoading,
  todayFeedbackPending,
  user,
  getProfile,
  fetchPermissions,
  fetchCurrentCompanies,
  fetchDayFeedback,
  sidebarOpen
}) {
  const [showBlurOverlay, setShowBlurOverlay] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  if (
    !isSignedIn &&
    !localStorage.getItem('redirection-url') &&
    !['/login', '/welcome', '/onboarding', '/forgot_password'].includes(location.pathname)
  ) {
    localStorage.setItem('redirection-url', location.pathname)
  }

  useLayoutEffect(() => {
    if (isEmpty(company)) {
      fetchCurrentCompanies()
    }
    const search_params = location.search.substring(1)?.split('&')
    if (search_params.length > 1) setImpersonateData(search_params)
  }, [])

  useEffect(() => {
    const handleMessage = (event) => {
      handleReceiveMessage(event, navigate, setShowBlurOverlay, sidebarOpen, company.subdomain)
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [sidebarOpen, company])

  useEffect(() => {
    const handleResize = () => showBlurOverlay && handleModalOffset(sidebarOpen, company.subdomain)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [showBlurOverlay, sidebarOpen, company])

  useLayoutEffect(() => {
    if (location.search && location.search !== '') {
      let param = parse(location.search, { ignoreQueryPrefix: true })
      if (param.error) toastr.error(param.error)
    }
    if (!user && isSignedIn && userId && permissions.length === 0) {
      fetchPermissions()
      company.user_sentiment_feedback_enabled &&
        setTimeout(() => {
          fetchDayFeedback(userId)
        }, 7000)
      getProfile(userId)
    }
  }, [userId, getProfile])

  const isUserLoading = useCallback(() => isLoading || (userId && !user), [isLoading, userId, user])

  const hasFaqPermission = !checkPermission(permissions, 'faqs', 'general', 'faqs_panel').isNone()

  let cannotRender = cannotRenderApp(isUserLoading, {
    isSignedIn,
    userId,
    permissions
  })

  const unprotectedCheck = unprotectedPages.includes(location.pathname)
  // const isOnboardingPage = location.pathname === '/onboarding'
  // const loginToken = location.search && location.search.split('=')[1]

  return (
    <ThemeProvider theme={appTheme}>
      <Provider config={() => rollbarConfig({ userName: user?.name, userId: userId })}>
        <Suspense fallback={<Delay />}>
          <ErrorBoundaryWrapper errorMessage={'Error in my app'}>
            {isImpersonateMode() && (
              <Typography variant="title" className="impersonate-div" component="p">
                <center>
                  <b>You are in Impersonate mode</b>
                </center>
              </Typography>
            )}
            <ModalRoot />
            {showBlurOverlay && <BlurOverlay />}
            <ReduxToastr
              timeOut={5000}
              newestOnTop={true}
              getState={(state) => state.toastr}
              position="top-right"
              transitionIn="bounceIn"
              transitionOut="bounceOut"
              progressBar
              closeOnToastrClick
            />
            <ToastProvider>
              <Switch>
                <Route path="/login" Component={LandingPage} exact={true} />
                {company && company.simple_login_enabled && (
                  <Fragment>
                    {location.search !== '' && <Route path="/welcome" Component={ResetPasswordPage} exact={true} />}
                    <Route path="/forgot_password" Component={ForgotPasswordPage} exact={true} />
                  </Fragment>
                )}
                <Route path="/onboarding" Component={UserPreboardingFlow} exact={false} />
                {!isSignedIn &&
                  !localStorage.getItem('access-token') &&
                  !['/onboarding', '/welcome'].includes(location.pathname) && (
                    <Route path="*" element={<Navigate to="/login" replace />} />
                  )}
                {user &&
                  !user.support_staff &&
                  !user.fields_fill &&
                  !location.pathname.includes('/initial_details') && (
                    <Route path="*" element={<Navigate to="/initial_details" replace />} />
                  )}
              </Switch>
              {cannotRender && <Overlay />}
              {!cannotRender && isSignedIn && user && (
                <Fragment>
                  <Backdrop />
                  <div className="app-main">
                    {!unprotectedCheck && user && isSignedIn && (
                      <>
                        {!location.pathname.includes('/initial_details') && <Sidebar />}
                        <Header />
                        <BottomActionBar />
                      </>
                    )}
                    {/* {((isOnboardingPage && loginToken === localStorage.preonboardingToken) || */}
                    {/*   (!unprotectedCheck && user && isSignedIn)) && <MapleBotWidget />} */}

                    <div className="app-content">
                      <div className={classNames('app-content-div', { 'z-index-infinite': showBlurOverlay })}>
                        {isSignedIn && userId && todayFeedbackPending && company.user_sentiment_feedback_enabled && (
                          <DayFeedback />
                        )}
                        <Routes />
                      </div>
                      {location.pathname === '/login' ? null : <Footer hasFaqPermission={hasFaqPermission} />}
                    </div>
                  </div>
                </Fragment>
              )}
            </ToastProvider>
          </ErrorBoundaryWrapper>
        </Suspense>
      </Provider>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  const user = state.reduxTokenAuth.currentUser.attributes
  const currentUser = state.reduxTokenAuth.currentUser

  if (
    currentUser.hasVerificationBeenAttempted &&
    !currentUser.isLoading &&
    !currentUser.isSignedIn &&
    !isImpersonateMode()
  ) {
    localStorage.removeItem('access-token')
    localStorage.removeItem('client')
    localStorage.removeItem('uid')
  }

  return {
    permissions: state.permissions.userPermissions,
    hasVerificationBeenAttempted: currentUser.hasVerificationBeenAttempted,
    isSignedIn: currentUser.isSignedIn,
    company: state.currentCompany,
    isLoading: currentUser.isLoading,
    user: state.users.allUserProfiles[user.id],
    sidebarOpen: state.sidebar.open,
    todayFeedbackPending: state.users.todayFeedbackPending,
    userId: user.id
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchPermissions: () => {
    dispatch(fetchPermissions(false, fetchPermissionSuccess, fetchPermissionFailure))
  },
  getProfile: (params) => {
    dispatch(getProfile(params, getProfileSuccess, getProfileFailure))
  },
  fetchDayFeedback: (userId) => {
    dispatch(fetchDayFeedback(userId, fetchDayFeedbackSuccess, fetchDayFeedbackFailure))
  },
  fetchCurrentCompanies: () => {
    dispatch(fetchCurrentCompanies(fetchCurrentCompanySuccess, fetchCurrentCompanyFailure))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
